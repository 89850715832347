import styled from "styled-components";

export const SecondaryBtn = styled.button`
  width: 100%;
  max-width: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  cursor: pointer;
  background-color: white;

  color: black;
  outline: none;
  border: 1px solid black;
  border-radius: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
`;
