import styled from "styled-components";

import { H4 } from "../../atoms/h4/styles";

import { device } from "../../utils/media-queries";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 120px 100px 100px 100px;
  @media ${device.laptop} {
    padding: 120px 80px 80px 80px;
  }
  @media ${device.tablet} {
    padding: 80px 48px 48px 48px;
  }
  @media ${device.mobile} {
    padding: 80px 24px 24px 24px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1040px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ParagraphTitle = styled(H4)`
  font-weight: 400;
  font-size: 30px;
`;

export const List = styled.ul`
  padding-left: 40px;
`;

export const ListItem = styled.li`
  margin-top: 10px;
`;

export const PolicyMainContainer = styled.div`
  /* width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  background: linear-gradient(166.39deg, #fffaf3 5.83%, #ffe8cc 43.62%); */

  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 120px 100px 100px 100px;
  @media ${device.laptop} {
    padding: 120px 80px 80px 80px;
  }
  @media ${device.tablet} {
    padding: 80px 48px 48px 48px;
  }
  @media ${device.mobile} {
    padding: 80px 24px 24px 24px;
  }

`;