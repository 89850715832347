import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { openWindow } from "../../api";
import { Signer } from "casper-js-sdk";
import { getCurrentDateUTC } from "../../utils";
import { useRef, useState } from "react";

import {
  DayText,
  WindowImage,
  WindowItemStyled,
  WindowImageClickable,
} from "./styles";
import WindowItemAnim from "../window-item-anim";

import WindowInnerSponsor from "../../img/assets/window-inner-sponsor.svg";
import WindowInnerStandard from "../../img/assets/window-inner-standard.svg";
import WindowInnerJoker from "../../img/assets/window-inner-joker.svg";
import WindowClosed from "../../img/assets/window-closed.svg";
import WindowMissed from "../../img/assets/window-missed.svg";
import WindowOpened from "../../img/assets/window-opened.svg";
import WindowJoker from "../../img/assets/window-opened-joker.svg";
import WindowJokerGold from "../../img/assets/window-opened-joker-gold.svg";
import WindowOpenedSponsor from "../../img/assets/window-opened-sponsor.svg";
import WindowOpenedSponsorGold from "../../img/assets/window-opened-sponsor-gold.svg";
import WindowOpenedSponsorPremium from "../../img/assets/window-opened-sponsor-premium.svg";

const WindowItem = ({
  dayType,
  day,
  currentTime,
  signerPubKey,
  refCode,
  asset,
}) => {
  const currentDate = getCurrentDateUTC(currentTime);
  const currentDay = new Date(currentDate).getDate();
  //const currentMonth = currentDate.getMonth();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const lottieRef = useRef();
  const [clickDisable, setClickDisable] = useState(false);

  //Handle window open
  const handleOpen = () => {
    executeRecaptcha().then((token) => {
      if (signerPubKey) {
        Signer.signMessage("Merry christmas!", signerPubKey).then(
          (signedMessage) => {
            openWindow(signedMessage, refCode, signerPubKey, token).then(
              (response) => {
                if (response.status === 200) {
                  lottieRef.current.play();
                  setClickDisable(true);
                  setTimeout(() => {
                    navigate(
                      asset?.name
                        ? `dec-${day}-${asset.name
                            .replace(/\W/g, "")
                            .toLowerCase()}`
                        : `dec-${day}`
                    );
                  }, 2000);
                } else {
                  response.json().then((e) => {
                    alert(e.message);
                  });
                }
              }
            );
          }
        );
      }
    });
  };
  const handleUpgrade = () => {
    if (signerPubKey && refCode) {
      executeRecaptcha().then((token) => {
        Signer.signMessage("Merry christmas!", signerPubKey).then(
          (signedMessage) => {
            openWindow(signedMessage, refCode, signerPubKey, token).then(
              (response) => {
                if (response.status === 200) {
                  navigate(
                    asset?.name
                      ? `dec-${day}-${asset.name
                          .replace(/\W/g, "")
                          .toLowerCase()}`
                      : `dec-${day}`
                  );
                } else {
                  response.json().then((e) => {
                    alert(e.message);
                  });
                }
              }
            );
          }
        );
      });
    } else {
      navigate(
        asset?.name
          ? `dec-${day}-${asset.name.replace(/\W/g, "").toLowerCase()}`
          : `dec-${day}`
      );
    }
  };
  return (
    <WindowItemStyled>
      {(() => {
        switch (dayType) {
          case 0:
            // TODO: Refactor this in future!
            // if (currentDay > day) {
            //   if (signerPubKey) {
            //     return (
            //       <WindowImageClickable
            //         onClick={() => {
            //           navigate(
            //             asset?.name
            //               ? `dec-${day}-${asset.name
            //                   .replace(/\W/g, "")
            //                   .toLowerCase()}`
            //               : `dec-${day}`
            //           );
            //         }}
            //         src={WindowMissed}
            //         alt="window missed"
            //       />
            //     );
            //   } else {
            //     if (asset) {
            //       switch (asset.type) {
            //         case "no-sponsor":
            //           return (
            //             <WindowImageClickable
            //               onClick={() => {
            //                 navigate(
            //                   asset?.name
            //                     ? `dec-${day}-${asset.name
            //                         .replace(/\W/g, "")
            //                         .toLowerCase()}`
            //                     : `dec-${day}`
            //                 );
            //               }}
            //               src={WindowOpened}
            //               alt="window opened"
            //             />
            //           );
            //         case "joker":
            //           return (
            //             <WindowImageClickable
            //               onClick={() => {
            //                 navigate(
            //                   asset?.name
            //                     ? `dec-${day}-${asset.name
            //                         .replace(/\W/g, "")
            //                         .toLowerCase()}`
            //                     : `dec-${day}`
            //                 );
            //               }}
            //               src={WindowJoker}
            //               alt="window joker"
            //             />
            //           );
            //         default:
            //           return (
            //             <WindowImageClickable
            //               onClick={() => {
            //                 navigate(
            //                   asset?.name
            //                     ? `dec-${day}-${asset.name
            //                         .replace(/\W/g, "")
            //                         .toLowerCase()}`
            //                     : `dec-${day}`
            //                 );
            //               }}
            //               src={WindowOpenedSponsor}
            //               alt="window sponsor"
            //             />
            //           );
            //       }
            //     } else {
            //       return (
            //         <WindowImageClickable
            //           onClick={() => {
            //             navigate(
            //               asset?.name
            //                 ? `dec-${day}-${asset.name
            //                     .replace(/\W/g, "")
            //                     .toLowerCase()}`
            //                 : `dec-${day}`
            //             );
            //           }}
            //           src={WindowOpened}
            //           alt="window openeded"
            //         />
            //       );
            //     }
            //   }
            // } else if (currentDay === day) {
            //   if (signerPubKey) {
            //     if (asset) {
            //       switch (asset.type) {
            //         case "no-sponsor":
            //           return (
            //             <WindowItemAnim
            //               handleOpen={handleOpen}
            //               lottieRef={lottieRef}
            //               windowInnerSrc={WindowInnerStandard}
            //               clickDisable={clickDisable}
            //             />
            //           );
            //         case "joker":
            //           return (
            //             <WindowItemAnim
            //               handleOpen={handleOpen}
            //               lottieRef={lottieRef}
            //               windowInnerSrc={WindowInnerJoker}
            //               clickDisable={clickDisable}
            //             />
            //           );
            //         default:
            //           return (
            //             <WindowItemAnim
            //               handleOpen={handleOpen}
            //               lottieRef={lottieRef}
            //               windowInnerSrc={WindowInnerSponsor}
            //               clickDisable={clickDisable}
            //             />
            //           );
            //       }
            //     } else {
            //       return (
            //         <WindowItemAnim
            //           handleOpen={handleOpen}
            //           lottieRef={lottieRef}
            //           windowInnerSrc={WindowInnerStandard}
            //         />
            //       );
            //     }
            //   } else {
            //     return <WindowImage src={WindowClosed} alt="window closed" />;
            //   }
            // } else {
            //   return <WindowImage src={WindowClosed} alt="window closed" />;
            // }
          case 1:
            if (asset) {
              switch (asset.type) {
                case "no-sponsor":
                  return (
                    <WindowImageClickable
                      onClick={handleUpgrade}
                      src={WindowOpened}
                      alt="window opened"
                    />
                  );
                case "joker":
                  return (
                    <WindowImageClickable
                      onClick={handleUpgrade}
                      src={WindowJoker}
                      alt="window joker"
                    />
                  );
                default:
                  return (
                    <WindowImageClickable
                      onClick={handleUpgrade}
                      src={WindowOpenedSponsor}
                      alt="window sponsor"
                    />
                  );
              }
            } else {
              return (
                <WindowImageClickable
                  onClick={handleUpgrade}
                  src={WindowOpened}
                  alt="window opened"
                />
              );
            }

          case 2:
            if (asset) {
              switch (asset.type) {
                case "no-sponsor":
                  return (
                    <WindowImageClickable
                      onClick={() => {
                        navigate(
                          asset?.name
                            ? `dec-${day}-${asset.name
                                .replace(/\W/g, "")
                                .toLowerCase()}`
                            : `dec-${day}`
                        );
                      }}
                      src={WindowOpened}
                      alt="window opened"
                    />
                  );

                case "premium":
                  return (
                    <WindowImageClickable
                      onClick={() => {
                        navigate(
                          asset?.name
                            ? `dec-${day}-${asset.name
                                .replace(/\W/g, "")
                                .toLowerCase()}`
                            : `dec-${day}`
                        );
                      }}
                      src={WindowOpenedSponsorPremium}
                      alt="window sponsor"
                    />
                  );
                case "joker":
                  return (
                    <WindowImageClickable
                      onClick={() => {
                        navigate(
                          asset?.name
                            ? `dec-${day}-${asset.name
                                .replace(/\W/g, "")
                                .toLowerCase()}`
                            : `dec-${day}`
                        );
                      }}
                      src={WindowJokerGold}
                      alt="window joker"
                    />
                  );
                default:
                  return (
                    <WindowImageClickable
                      onClick={() => {
                        navigate(
                          asset?.name
                            ? `dec-${day}-${asset.name
                                .replace(/\W/g, "")
                                .toLowerCase()}`
                            : `dec-${day}`
                        );
                      }}
                      src={WindowOpenedSponsorGold}
                      alt="window sponsor"
                    />
                  );
              }
            } else {
              return (
                <WindowImageClickable
                  onClick={() => {
                    navigate(
                      asset?.name
                        ? `dec-${day}-${asset.name
                            .replace(/\W/g, "")
                            .toLowerCase()}`
                        : `dec-${day}`
                    );
                  }}
                  src={WindowOpened}
                  alt="window opened"
                />
              );
            }
          default:
            return <WindowImage src={WindowClosed} alt="window closed" />;
        }
      })()}

      <DayText>{day + " Dec."}</DayText>
    </WindowItemStyled>
  );
};

export default WindowItem;
