import styled from "styled-components";

export const VideoLoaderWrapper = styled.div`
  width: 100%;
  max-width: ${({ width }) => `${width}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: ${({ width, height }) => `${width} / ${height}`};
  position: relative;
`;

export const Spinner = styled.div`
  width: 100%;
  max-width: ${({ width }) => `${width}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: ${({ width, height }) => `${width} / ${height}`};
  position: absolute;
`;
