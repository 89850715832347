import styled from "styled-components";

export const WindowItemStyled = styled.div`
  width: 100%;
  max-width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-decoration: none;
`;

export const WindowImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
`;

export const WindowImageClickable = styled.img`
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  height: auto;
`;

export const DayText = styled.div`
  color: #f8dc7a;
  font-style: italic;
  font-weight: 700;
  font-size: 27px;
`;
