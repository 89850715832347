import styled from "styled-components";
import { device } from "../../utils/media-queries.js";

export const ProfileModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
`;

export const Modal = styled.div`
  width: 100%;
  max-width: 560px;
  min-height: 528px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 24px 44px;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    width: calc(100% - 48px);
  }
`;

export const Title = styled.h2`
  font-family: "Volkhov";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  align-self: center;
  margin: 2px 0 12px 0;
`;

export const CloseIcon = styled.img`
  width: 22px;
  height: 22px;
  align-self: flex-end;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(0.9);
  }
`;

export const InfoText = styled.p`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  min-height: 72px;
  text-transform: uppercase;
`;

export const AgreeText = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
`;

export const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 28px 0;
`;

export const Input = styled.input`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ emailError }) => (emailError ? "red" : "#dddee3")};
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
  height: 48px;
  margin-top: 8px;
  padding: 14px 12px;
`;

export const Label = styled.label`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const ErroMessage = styled.p`
  padding-top: 8px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #ee8175;
`;
export const WindowContainer = styled.div`
  display: flex;
  padding-top: 36px;
  justify-content: space-between;
`;
export const WindowImage = styled.img`
  width: 100%;
  max-width: 106px;
  height: auto;
`;
export const CountersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CounterText = styled.p`
  font-family: "Volkhov";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  width: 106px;
  white-space: nowrap;
`;
export const CounterTextBold = styled.span`
  font-weight: 700;
`;
export const LineImage = styled.img`
  padding: 48px 0 28px 0;
`;
