import React from "react";
import { Link } from "react-router-dom";

import {
  FooterContainer,
  FooterContent,
  FooterLinksBlock,
  FooterLink,
  FooterShortLine,
  FooterMailbox,
  Logo,
  FooterSocialBlock,
  SocialIcon,
  FooterCopyright,
  FooterLogo,
} from "./styles";

import {
  URL_BLOCKBITES,
  URL_FAQ,
  URL_LINKEDIN,
  URL_MEDIUM,
  URL_PRIVACY_POLICY,
  URL_TELEGRAM,
  URL_TWITTER,
  URL_YOUTUBE,
} from "../../constants";

import footerLogoBlockbites from "../../img/logos/bb-logo.svg";
import linkedinButton from "../../img/social/linkedin.svg";
import twitterButton from "../../img/social/twitter.svg";
import youtubeButton from "../../img/social/youtube.svg";
import unionButton from "../../img/social/medium.svg";
import telegramIcon from "../../img/social/telegram.svg";
import mailtoButton from "../../img/assets/mail-icon.svg";
import { CaneBottom } from "../categories-window/styles";
import CaneSrc from "../../img/assets/cane-img.svg";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinksBlock>
          <FooterLink as={Link} to={URL_FAQ}>
            F.A.Q
          </FooterLink>
          <FooterLink as={Link} to={URL_PRIVACY_POLICY}>
            Privacy policy
          </FooterLink>
        </FooterLinksBlock>
        <FooterShortLine />
        <a href={URL_BLOCKBITES} target="_blank" rel="noreferrer">
          <FooterLogo src={footerLogoBlockbites} alt="logo-blockbites" />
        </a>
        <FooterMailbox href="mailto:hello@blockbit.es">
          <Logo
            src={mailtoButton}
            style={{ width: "14px", margin: "0 13px 0 0" }}
          />{" "}
          hello@blockbit.es
        </FooterMailbox>
        <FooterSocialBlock>
          <a href={URL_LINKEDIN} target="blank" rel="noreferrer">
            <SocialIcon src={linkedinButton} />
          </a>
          <a href={URL_TWITTER} target="blank" rel="noreferrer">
            <SocialIcon src={twitterButton} />
          </a>
          <a href={URL_YOUTUBE} target="_blank" rel="noreferrer">
            <SocialIcon src={youtubeButton} />
          </a>
          <a href={URL_MEDIUM} target="_blank" rel="noreferrer">
            <SocialIcon src={unionButton} />
          </a>
          <a href={URL_TELEGRAM} target="_blank" rel="noreferrer">
            <SocialIcon src={telegramIcon} />
          </a>
        </FooterSocialBlock>
        <CaneBottom src={CaneSrc} />
        <FooterCopyright>Ⓒ 2022 Blockbites</FooterCopyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
