import styled from "styled-components";
import { device } from "../../utils/media-queries";

export const PagePositioner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 45px;
  z-index: 2;
`;

export const CategoriesWindowWrapper = styled.div`
  width: 100%;
  max-width: 808px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #b68819 -28.28%, #f9dd7b 16.53%);
  box-shadow: 0px 25px 21px rgba(28, 23, 0, 0.3);
  border-radius: 25px;
  padding: 28px;
  color: #960000;
  font-family: "Volkhov";
  font-style: normal;
  z-index: 99;
  position: relative;
  margin-bottom: 45px;
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  margin-bottom: 16px;
`;

export const SubTitle = styled.h4`
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
`;

export const BallsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 80px 32px;
  padding: 60px;

  @media ${device.laptop} {
    padding: 34px 0;
    gap: 36px 0;
  }
`;

export const CaneLeft = styled.img`
  width: 100%;
  max-width: 165px;
  height: auto;
  transform: rotate(21.55deg);
  position: absolute;
  left: calc(50vw - 750px);
  top: -135px;
  z-index: 1;

  @media ${device.laptop} {
    left: -20px;
  }

  @media ${device.tablet} {
    left: -70px;
    top: -50px;
  }

  @media ${device.mobile} {
    left: -130px;
    top: -50px;
  }
`;

export const CaneRight = styled(CaneLeft)`
  left: unset;
  right: -300px;
  top: calc(50% - 150px);
  transform: matrix(-0.94, 0.34, 0.34, 0.94, 0, 0);

  @media ${device.laptop} {
    right: -150px;
    left: unset;
  }
`;

export const CaneBottom = styled(CaneLeft)`
  top: unset;
  bottom: -300px;
  left: calc(50vw - 400px);
  transform: rotate(-20.63deg);
  z-index: 3;

  @media ${device.laptop} {
    left: 50px;
  }

  @media ${device.tablet} {
    left: 0;
  }
`;

export const GreenBoxFake = styled.div`
  width: 100vw;
  height: 250px;
  background: linear-gradient(90deg, #1c7716 14.24%, #092607 204.27%);
  position: absolute;
  bottom: 0px;
`;
