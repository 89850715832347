import React, { useEffect, useState } from "react";
import range from "lodash.range";

import {
  CaneLeft,
  CaneRight,
  GreenBoxFake,
  NftsContainer,
  PagePositioner,
} from "./styles";
import CategoryBanner from "../../molecules/category-banner";
import NftCard from "../../atoms/nft-card";
import {
  WIDTH_BREAKPOINT_PAGINATION_2_COLUMNS,
  WIDTH_BREAKPOINT_PAGINATION_3_COLUMNS,
} from "../../constants";

import CaneSrc from "../../img/assets/cane-img.svg";
import Pagination from "../../molecules/pagination";

const CategorySubpage = ({ title, ballImg, nfts, width }) => {
  const [currentPage, setCurrentPage] = useState(1);

  //Nfts per page depends on screen width
  const screenWidthVsNftPerPage = () => {
    if (width < WIDTH_BREAKPOINT_PAGINATION_2_COLUMNS) {
      return 6;
    }
    if (width < WIDTH_BREAKPOINT_PAGINATION_3_COLUMNS) {
      return 12;
    } else {
      return 24;
    }
  };

  //Fix to avoid current page number set out of range
  useEffect(() => {
    setCurrentPage(1);
  }, [width]);

  if (!nfts.length) return;

  const maxNftNumToDisplay = nfts.length;
  const nftsPerPage = screenWidthVsNftPerPage();
  const indexOfLastNft = currentPage * nftsPerPage + +nfts[0].id;
  const indexOfFirstNft = indexOfLastNft - nftsPerPage;

  //Page numbers
  const maxPageNumber = Math.ceil(maxNftNumToDisplay / nftsPerPage);

  //Change page
  const nextPage = (pageNum) => {
    if (pageNum < maxPageNumber) {
      setCurrentPage(pageNum + 1);
    }
  };
  const prevPage = (pageNum) => {
    if (pageNum - 1 > 0) {
      setCurrentPage(pageNum - 1);
    }
  };

  return (
    <PagePositioner>
      <CategoryBanner title={title} ballImg={ballImg} />
      <NftsContainer>
        <CaneLeft src={CaneSrc} />
        <CaneRight src={CaneSrc} />
        {range(indexOfFirstNft, indexOfLastNft).map((idx) => {
          const nftToShow = nfts.find((n) => parseInt(n.id) === idx);
          return nftToShow ? <NftCard data={nftToShow} key={idx} /> : null;
        })}
      </NftsContainer>
      {maxPageNumber === 1 ? null : (
        <Pagination
          currentPage={currentPage}
          nextPage={nextPage}
          prevPage={prevPage}
          maxPageNumber={maxPageNumber}
        />
      )}
      <GreenBoxFake />
    </PagePositioner>
  );
};

export default CategorySubpage;
