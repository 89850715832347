import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Signer } from "casper-js-sdk";
import { getUser } from "../../api";

import { URL_PROFILE } from "../../constants";

import {
  ConnectedForm,
  ConnectedFormButton,
  ConnectedFormText,
  ConnectedFormWallet,
  Header,
  HeaderContainer,
  HeaderInnerContainer,
  Logo,
} from "./styles";

import nutcrackerSmallLogo from "../../img/logos/nutcracker-small-logo.svg";
import WalletIconSrcConnected from "../../img/assets/wallet-icon-connected.svg";
import WalletIconSrcDisconnected from "../../img/assets/wallet-icon-disconnected.svg";

const Navbar = ({ signerPubKey, setProfileModalOpened }) => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isProfileUrlActive, setIsProfileUrlActive] = useState(false);
  const location = useLocation();
  const isConnected = signerPubKey !== null;

  //Connect
  const handleConnect = () => {
    if (isConnected) {
      Signer.disconnectFromSite();
    } else {
      try {
        Signer.sendConnectionRequest();
      } catch (e) {
        alert(e);
      }
    }
  };

  //Check register
  useEffect(() => {
    if (signerPubKey) {
      getUser(signerPubKey).then((response) => {
        if (response.status === 200) {
          setIsRegistered(true);
        } else {
          setIsRegistered(false);
        }
      });
    } else {
      setIsRegistered(false);
    }
  }, [signerPubKey]);

  //Check location
  useEffect(() => {
    if (location.pathname.substring(0, 3) !== "/c/") {
      setIsProfileUrlActive(true);
    } else {
      setIsProfileUrlActive(false);
    }
  }, [location.pathname]);

  return (
    <HeaderContainer>
      <HeaderInnerContainer>
        <Header>
          <Link to="/">
            <Logo src={nutcrackerSmallLogo} alt="" />
          </Link>
          <ConnectedForm>
            <ConnectedFormWallet
              onClick={handleConnect}
              src={
                isConnected ? WalletIconSrcConnected : WalletIconSrcDisconnected
              }
            />
            <ConnectedFormText onClick={handleConnect}>
              {isConnected ? "Connected" : "Disconnected"}
            </ConnectedFormText>
            {isConnected ? (
              isProfileUrlActive && isRegistered ? (
                <ConnectedFormButton as={Link} to={URL_PROFILE}>
                  Profile
                </ConnectedFormButton>
              ) : (
                <ConnectedFormButton
                  onClick={() => setProfileModalOpened(true)}
                >
                  {isRegistered ? "Profile" : "Register"}
                </ConnectedFormButton>
              )
            ) : null}
          </ConnectedForm>
        </Header>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
};

export default Navbar;
