import styled from "styled-components";
import { PrimaryBtn } from "../../atoms/primary-btn/styles";
import { device } from "../../utils/media-queries";

export const InfoBox = styled.div`
  width: 100%;
  max-width: 596px;
  background-color: white;
  margin-top: -42px;
  box-shadow: 0px 25px 21px rgba(28, 23, 0, 0.3);
  border-radius: 25px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Volkhov";

  @media ${device.mobile} {
    padding: 40px 20px;
  }
`;

export const BoxTitle = styled.h3`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin-top: 62px;
  text-align: center;
`;

export const Subtitle = styled.h4`
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  text-align: center;
  margin-top: 12px;
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
`;

export const Btns = styled.h4`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export const BatchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  gap: 4px;
  margin: 28px 0 32px 0;
`;

export const BatchLogo = styled.img`
  width: 100%;
  max-width: 186px;
  height: auto;
  margin-bottom: 12px;
`;

export const HashContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const IsYourNft = styled(Text)`
  margin-bottom: 24px;
`;

export const MetaDataContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 56px 48px;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid #d9d9d9;
  padding-top: 32px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: 28px;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    gap: 28px;
  }
`;

export const MetaDataItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-left: 1px solid #8f8f8f;
  padding-left: 12px;
  min-height: 56px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
`;

export const OwnerLabel = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const HashField = styled.div`
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #8f8f8f;
  border-radius: 10px;
  padding: 14px 12px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #bababa;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SocialIcon = styled.img`
  width: 29px;
  height: auto;
  margin-right: 12px;
`;

export const GoBackBtn = styled(PrimaryBtn)`
  text-decoration: none;
  text-transform: uppercase;
`;
