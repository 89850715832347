import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import WindowItem from "../../molecules/window-item";
import { DotsDivider } from "../../atoms/dots-devider/styles";
import { CalendarContent, CalendarWrapper, LongLine } from "./styles";
import { FooterLongLine } from "../../molecules/footer/styles";

import { getAssets, getTime, getWindows } from "../../api";

const Calendar = ({ signerPubKey }) => {
  const [windows, setWindows] = useState(new Array(24).fill(0));
  const [currentTime, setCurrentTime] = useState([]);
  const [assets, setAssets] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (signerPubKey) {
      getWindows(signerPubKey).then((response) => {
        if (response.status === 200) {
          response.json().then(({ currentTime, days }) => {
            setWindows(days);
          });
        }
      });
    } else {
      setWindows(new Array(24).fill(0));
    }
  }, [signerPubKey]);

  useEffect(() => {
    getTime().then((response) => {
      setCurrentTime(response);
    });

    getAssets().then((response) => {
      response.json().then((info) => {
        setAssets(info);
      });
    });
  }, []);

  if (!windows || !currentTime || !assets) return;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>chainlendar.io - the first blockchain advent calendar</title>
      </Helmet>
      <CalendarWrapper>
        <LongLine />
        <DotsDivider />
        <CalendarContent>
          {windows.map((dayType, index) => {
            const i = assets?.findIndex((e) => e.dayNumber === index + 1);
            return (
              <WindowItem
                key={index}
                dayType={dayType}
                day={index + 1}
                currentTime={currentTime}
                signerPubKey={signerPubKey}
                refCode={searchParams.get("refCode")}
                asset={assets[i]}
              />
            );
          })}
        </CalendarContent>
        <DotsDivider />
        <FooterLongLine />
      </CalendarWrapper>
    </>
  );
};

export default Calendar;
