import styled from "styled-components";
import { device } from "../../utils/media-queries";

import NCLogoSrc from "../../img/assets/nutcracker-logo.svg";

export const Banner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px 0 40px 0;
  flex: 1 1;

  @media ${device.laptop} {
    flex-direction: column-reverse;
    gap: 26px;
  }

  @media ${device.tablet} {
    padding: 28px 0;
  }
`;

export const NCBLogo = styled.div`
  width: 100%;
  max-width: 467px;
  background: url(${NCLogoSrc}) no-repeat center;
  height: 0;
  padding-top: 36.4%;
  background-size: contain;
`;

export const BannerTextWrapper = styled.div`
  width: 100%;
  max-width: 467px;
  flex-basis: 467px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media ${device.laptop} {
    flex-basis: unset;
    justify-content: flex-start;
  }
`;

export const LogoText = styled.div`
  width: 100%;
  max-width: 300px;
  color: white;
  font-family: "Volkhov";
  font-style: italic;
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  text-align: center;

  @media ${device.tablet} {
    font-size: 26px;
    line-height: 34px;
    max-width: 300px;
  }
`;
