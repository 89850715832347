
import styled from "styled-components";

import { device } from "../../utils/media-queries";

export const Body = styled.p`
  font-size: 18px;
  font-family: 'Lato', sans-serif;;
  font-weight: 400;
  line-height: 160%;
  color: black;
  @media ${device.tablet} {
    font-size: 16px;
  }
`;