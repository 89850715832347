import React from "react";

import {
  BallsContainer,
  CategoriesWindowWrapper,
  Title,
  SubTitle,
  CaneRight,
  GreenBoxFake,
  CaneLeft,
  PagePositioner,
} from "./styles";
import CategoryBall from "../../atoms/category-ball";

import CaneSrc from "../../img/assets/cane-img.svg";
import { NFT_CATEGORIES_ARRAY } from "../../constants";

const CategoriesWindow = () => {
  return (
    <PagePositioner>
      <CaneLeft src={CaneSrc} />
      <CategoriesWindowWrapper>
        <CaneRight src={CaneSrc} />
        <Title>Winner's NFT rewards</Title>
        <SubTitle>Check the chainlendar NFT collections</SubTitle>
        <BallsContainer>
          {NFT_CATEGORIES_ARRAY.map((category, id) => {
            let fixedCategoryName = category
              .toLocaleLowerCase()
              .replace(" ", "-");
            return (
              <CategoryBall
                key={id}
                title={category}
                imgSrc={`assets/category-ball/${fixedCategoryName}-ball.svg`}
                target={`/cat-${fixedCategoryName}`}
              />
            );
          })}
        </BallsContainer>
      </CategoriesWindowWrapper>
      <GreenBoxFake />
    </PagePositioner>
  );
};

export default CategoriesWindow;
