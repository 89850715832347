import styled from "styled-components";
import divideEllipse from "../../img/assets/divide_ellipse.png";
import { device } from "../../utils/media-queries.js";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

export const HeaderFooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const DesktopRequest = styled.div`
  display: none;

  @media ${device.laptop} {
    padding: 20px 0 0 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(166.39deg, #fffaf3 5.83%, #ffe8cc 43.62%);
  }
`;

export const DesktopRequestHeader = styled.div`
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #271b00;
`;

export const DesktopRequestText = styled.div`
  width: 90%;
  max-width: 600px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17.5238px;
  line-height: 26px;
  text-align: center;
  color: #271b00;
  margin: 16px 0 12px 0;
  padding: 0 10px;
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(166.39deg, #fffaf3 5.83%, #ffe8cc 43.62%);
`;

export const MidContainer = styled.div`
  width: 100%;
  height: 168px;
  background: linear-gradient(0deg, #ffc47a 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.6;
  transform: rotate(-180deg);
  display: flex;
  flex-direction: column;
  justify-content: end;

  @media ${device.tablet} {
    height: 68px;
  }
`;

export const Welcome = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
  padding: 0 0 0 124px;

  @media ${device.laptop} {
    padding: 0;
  }
`;

export const WelcomeContent = styled.div`
  width: calc(100% - 450px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media ${device.laptop} {
    width: 100%;
    padding: 0 16px;
  }
`;

export const NutCrackerBig = styled.img`
  align-self: end;
  width: 486px;

  @media ${device.laptop} {
    display: none;
  }
`;
export const WelcomeLogo = styled.img`
  max-width: 765px;
  margin: 87px 0 0 0;
  width: 100%;

  @media ${device.tablet} {
    margin: 0;
  }
`;
export const StyledH2 = styled.h2`
  font-size: 33px;
  font-weight: 400;
  line-height: 43px;
  text-align: center;
  color: #000000;
  margin: 58px 0 0 0;
  width: 100%;

  @media ${device.tablet} {
    margin: 40px 0 0 0;
    font-size: 28px;
    line-height: 32px;
  }

  @media ${device.mobile} {
    margin: 24px 0 0 0;
    font-size: 21px;
    line-height: 27px;
  }
`;

export const LiveClockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const LiveClock = styled.div`
  position: relative;
  top: 20%;
  color: #fff;
  font-style: normal;
  text-align: center;
  z-index: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    top: 0;
  }
`;
export const TimerBottom = styled.h4`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0px 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  z-index: 11;
  position: relative;
  top: 110px;
  left: -95px;

  @media ${device.tablet} {
    top: 180px;
    font-size: 14px;
    left: 18%;
    position: absolute;
  }

  @media ${device.mobile} {
    top: 110px;
    font-size: 9px;
    left: 18%;
    gap: 0px 10px;
  }
`;

export const CountDownImg = styled.img`
  width: 650px;
  position: absolute;
  top: 60px;
  z-index: 10;
  overflow: hidden;

  @media ${device.tablet} {
    width: 100%;
    top: 0;
    margin-top: 42px;
    left: 40px;
  }
`;

export const Logo = styled.img`
  width: auto;
`;

export const ImgDesktopRequest = styled.img`
  width: 100%;
  max-width: 303px;
  height: auto;
`;

export const HeaderLongLine = styled.div`
  background: linear-gradient(
    90deg,
    #bd871b 2.98%,
    #b68819 15.17%,
    #f9dd7b 45.19%,
    #f6da77 59.26%,
    #eed16d 70.51%,
    #e0c15c 80.83%,
    #ccac44 91.15%,
    #be9c32 96.77%
  );
  width: 100%;
  height: 9px;
`;

export const FAQ = styled.div`
  display: grid;
  grid-template-columns: 1fr 35px 1fr;
  gap: 0px 42px;
  margin: 246px 0 0 0;
  font-family: "Volkhov", serif;

  @media ${device.laptop} {
    margin: 200px 0 0 0;
    padding: 0 40px;
  }

  @media ${device.tablet} {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 100px 0 0 0;
    padding: 0 80px;
    flex-direction: ${({ reversed }) =>
      reversed ? "column-reverse" : "column"};
  }

  @media ${device.mobile} {
    padding: 0 40px;
  }
`;

export const FAQLeftBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  max-width: 580px;
  text-align: right;
`;
export const FAQRightBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  max-width: 580px;
  text-align: left;
`;

export const FAQImage = styled.img`
  max-width: 352px;
  justify-self: center;
  align-self: center;

  @media ${device.tablet} {
    width: 100%;
    height: auto;
  }
`;

export const FAQTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  height: 245px;

  @media ${device.tablet} {
    height: min-content;
  }
`;

export const StyledH1 = styled.h1`
  font-size: 48px;
  font-weight: 700;
  line-height: 71px;
  font-style: normal;
  color: #000000;
  max-width: 580px;

  @media ${device.tablet} {
    font-size: 28px;
    text-align: center;
    line-height: 36px;
  }
`;

export const StyledP = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin: 16px 0 0 0;
  max-width: 580px;

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const VerticalDivider = styled.div`
  height: 245px;
  width: 35px;
  background-image: url(${divideEllipse});
  background-repeat: repeat-y;
  grid-area: "divider";
  align-self: center;

  @media ${device.tablet} {
    display: none;
  }
`;

export const HorizontalDivider = styled.div`
  display: flex;
  flex-direction: column;
  width: 98vw;
  height: 35px;
  background: url(${divideEllipse}) space;
  margin: 220px 0 10px 0;
  @media ${device.tablet} {
    margin: 100px 0 10px 0;
  }
`;

export const WelcomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(132.31deg, #fffaf3 17.99%, #ffe8cc 109.12%);

  @media ${device.laptop} {
    padding-bottom: 280px;
  }

  @media ${device.tablet} {
    padding-bottom: 400px;
  }

  @media ${device.mobile} {
    padding-bottom: 250px;
  }
`;

export const MobileNutcrackerMainImg = styled.img`
  display: none;
  width: 100%;
  height: auto;
  padding: 20px 85px;

  @media ${device.tablet} {
    display: block;
    max-width: 400px;
  }
`;

export const LongLine = styled.div`
  background: linear-gradient(
    90deg,
    #bd871b 2.98%,
    #b68819 15.17%,
    #f9dd7b 45.19%,
    #f6da77 59.26%,
    #eed16d 70.51%,
    #e0c15c 80.83%,
    #ccac44 91.15%,
    #be9c32 96.77%
  );
  width: 100%;
  height: 9px;
`;
