import styled from "styled-components";
import { ArrowBtn } from "../../atoms/arrow-btn/styles";
import { device } from "../../utils/media-queries";

export const NftCardDetailsPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(360deg, #ff0000 89.2%, #b00000 107.67%);
  padding: 45px 48px 0 48px;
  overflow-x: hidden;
  margin-top: 88px;
  position: relative;

  @media ${device.tablet} {
    padding: 32px 48px 0 48px;
  }

  @media ${device.mobile} {
    padding: 24px 34px 0 34px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-bottom: 95px;
  margin-top: 32px;

  @media ${device.tablet} {
    margin-bottom: 42px;
  }
`;

export const GreenBoxFake = styled.div`
  width: 100vw;
  height: 250px;
  background: linear-gradient(90deg, #1c7716 14.24%, #092607 204.27%);
  position: absolute;
  bottom: 0px;

  @media ${device.tablet} {
    height: 200px;
  }
`;

export const SocialIcon = styled.img`
  width: 25px;
  height: auto;
`;

export const PrevBtn = styled(ArrowBtn)`
  margin-right: 40px;

  @media ${device.laptop} {
    margin: 0px;
    position: absolute;
    left: -15px;
    z-index: 99;
  }

  @media ${device.tablet} {
    left: -15px;
    bottom: -100px;
  }
`;

export const NextBtn = styled(PrevBtn)`
  margin-right: 0;
  margin-left: 40px;

  @media ${device.laptop} {
    left: unset;
    right: -15px;
  }

  @media ${device.tablet} {
    left: unset;
    right: -15px;
  }
`;

export const GoBackBtnPositioner = styled.div`
  align-self: flex-start;
  margin: 10px 0 24px 0;
`;

export const CaneLeft = styled.img`
  width: 100%;
  max-width: 165px;
  height: auto;
  transform: rotate(21.55deg);
  position: absolute;
  left: -250px;
  top: -80px;
  z-index: -99;

  @media ${device.laptop} {
    left: -20px;
    top: -60px;
  }

  @media ${device.tablet} {
    left: -200px;
    top: 150px;
  }
`;

export const CaneRight = styled(CaneLeft)`
  left: unset;
  z-index: 2;
  right: -150px;
  top: calc(50% - 150px);
  transform: matrix(-0.94, 0.34, 0.34, 0.94, 0, 0);

  @media ${device.laptop} {
    right: -150px;
    left: unset;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
