import styled from "styled-components";

export const PrimaryBtn = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  cursor: pointer;
  background-color: black;
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.2s;

  &:hover {
    background-color: #e1e1e1;
  }

  &:disabled {
    background-color: #434343;
  }
`;
