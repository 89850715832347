import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../molecules/image-loader";

import { Ball, BallText } from "./styles";

const CategoryBall = ({ title, imgSrc, target }) => {
  return (
    <Ball as={Link} to={target}>
      <ImageLoader
        width={300}
        height={300}
        image={imgSrc}
        spinnerColor={"#092607"}
      />
      <BallText>{title}</BallText>
    </Ball>
  );
};

export default CategoryBall;
