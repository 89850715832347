import React, { useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";

import { Spinner, TargetImage } from "./styles";

const ImageLoader = ({ image, width, height, spinnerColor }) => {
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
    });
  }

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      await preloadImage(image);

      if (isCancelled) {
        return;
      }

      setAssetsLoaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
    //eslint-disable-next-line
  }, []);

  return assetsLoaded ? (
    <TargetImage src={image} width={width} />
  ) : (
    <Spinner width={width} height={height}>
      <MoonLoader
        color={spinnerColor}
        loading
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Spinner>
  );
};

export default ImageLoader;
