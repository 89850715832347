import styled from "styled-components";

export const WindowOpenAnimWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
`;

export const LottieContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0px;
  top: -5px;
`;

export const WindowImageClickableAnim = styled.img`
  cursor: pointer;
  width: 100%;
  max-width: 244px;
  height: auto;
`;
