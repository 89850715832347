import styled from "styled-components";
import { device } from "../../utils/media-queries";

export const Ball = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  @media ${device.mobile} {
    max-width: unset;
  }
`;

export const BallText = styled.div`
  font-family: "Volkhov";
  font-style: italic;
  font-weight: 700;
  font-size: 23.5px;
  line-height: 30px;
  text-align: center;
  color: #9e1d1d;
`;
