import React from "react";
import { Link } from "react-router-dom";
import {
  ButtonsContainer,
  CookiesIllustration,
  CookiesText,
  CookiesWrapper,
  Modal,
  PrivacyPolicyText,
  Title,
} from "./styles";

import CookiesIllSrc from "../../img/assets/cookies-ill.svg";
import { PrimaryBtn } from "../../atoms/primary-btn/styles";
import { SecondaryBtn } from "../../atoms/secondary-btn/styles";

const CookiesModal = ({ setCookiesAccepted }) => {
  const handleAcceptCookiesClick = () => {
    localStorage.setItem("cookies-accept", "true");
    setCookiesAccepted(true);
  };

  return (
    <CookiesWrapper>
      <Modal>
        <Title>This website uses cookies</Title>
        <CookiesText>
          We use cookies to improve user experience. By clicking on ‘Accept all’
          you agree to the use of all cookies. If you only want to allow
          necessary cookies, click on ‘Decline’. For more details please see our{" "}
          <Link to="/privacy-policy">
            <PrivacyPolicyText>Privacy policy</PrivacyPolicyText>
          </Link>
        </CookiesText>
        <CookiesIllustration
          src={CookiesIllSrc}
          alt="nutcracker with cookies hat"
        />
        <ButtonsContainer>
          <PrimaryBtn onClick={() => handleAcceptCookiesClick()}>
            Accept All
          </PrimaryBtn>
          <SecondaryBtn>Decline</SecondaryBtn>
        </ButtonsContainer>
      </Modal>
    </CookiesWrapper>
  );
};

export default CookiesModal;
