import styled from "styled-components";
import { device } from "../../utils/media-queries.js";

export const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(161.69deg, #e07979 -11.09%, #790000 97.14%),
    linear-gradient(157.59deg, #1c7716 5.52%, #092607 76.61%);
`;

export const CalendarContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1600px;
  padding: 80px 160px;
  gap: 56px 26px;
  @media ${device.laptop} {
    padding: 80px 40px;
  }
`;

export const LongLine = styled.div`
  background: linear-gradient(
    90deg,
    #bd871b 2.98%,
    #b68819 15.17%,
    #f9dd7b 45.19%,
    #f6da77 59.26%,
    #eed16d 70.51%,
    #e0c15c 80.83%,
    #ccac44 91.15%,
    #be9c32 96.77%
  );
  width: 100%;
  height: 9px;
`;
