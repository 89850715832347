import styled from "styled-components";
import { device } from "../../utils/media-queries.js";

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-y: hidden;
`;

export const FooterContent = styled.footer`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #1c7716 14.24%, #092607 204.27%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.02);
`;

export const FooterLogo = styled.img`
  width: auto;
  margin: 28px 0 0 0;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const FooterMailbox = styled.a`
  color: white;
  margin: 12px 0 0 0;
  text-decoration: none;
  font-family: "Open Sans";
`;

export const FooterShortLine = styled.div`
  margin: 36px 0 0 0;
  width: 500px;
  height: 1px;
  background: linear-gradient(
    90deg,
    #bd871b 2.98%,
    #b68819 15.17%,
    #f9dd7b 45.19%,
    #f6da77 59.26%,
    #eed16d 70.51%,
    #e0c15c 80.83%,
    #ccac44 91.15%,
    #be9c32 96.77%
  );

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 500px;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const FooterSocialBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 29px;
  gap: 0px 48px;
  margin: 36px 0 0 0;
  justify-content: center;
  align-items: center;

  @media ${device.mobile} {
    gap: 24px;
  }
`;

export const SocialIcon = styled.img`
  width: 29px;
  height: auto;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const Logo = styled.img`
  width: auto;
`;

export const FooterLongLine = styled.div`
  background: linear-gradient(
    90deg,
    #bd871b 2.98%,
    #b68819 15.17%,
    #f9dd7b 45.19%,
    #f6da77 59.26%,
    #eed16d 70.51%,
    #e0c15c 80.83%,
    #ccac44 91.15%,
    #be9c32 96.77%
  );
  width: 100%;
  height: 9px;
`;

export const FooterLink = styled.a`
  font-family: "Open Sans";
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin: 12px 5px 0 5px;
  font-weight: 600;

  padding: 0 24px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:first-child {
    border-right: 2px solid white;
    padding: 0 32px;

    @media ${device.laptop} {
      border-right: none;
    }
  }
`;

export const FooterLinksBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 500px;
  margin-top: 36px;

  @media ${device.laptop} {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 0;
  }
`;

export const FooterCopyright = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.6;
  margin: 60px 0 20px 0;
`;
