import React from "react";
import { Link } from "react-router-dom";

import ImageLoader from "../../molecules/image-loader";

import { IdContainer, NftCardContainer } from "./styles";

const NftCard = ({ data }) => {
  return (
    <NftCardContainer as={Link} to={`/nft-details/${data.id}`}>
      <ImageLoader width={308} height={308} image={`${data.meta.asset}.svg`} />
      <IdContainer>#{data.id}</IdContainer>
    </NftCardContainer>
  );
};

export default NftCard;
