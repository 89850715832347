import styled from "styled-components";
import { device } from "../../utils/media-queries";

export const ProfilePageWrapper = styled.div`
  width: 100%;
  margin-top: 88px;
  background: linear-gradient(360deg, #ff0000 89.2%, #b00000 107.67%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  padding: 0 40px;

  @media ${device.laptop} {
    padding: 0 40px;
  }

  @media ${device.tablet} {
    padding: 0 24px;
  }

  @media ${device.mobile} {
    padding: 0 16px;
  }
`;

export const Collections = styled.div`
  width: 100%;
  max-width: 1356px;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 54px;
  position: relative;
  z-index: 3;
  padding-bottom: 65px;
`;

export const NoNftsCollected = styled.div`
  width: 100%;
  min-height: 470px;
  background: linear-gradient(180deg, #b68819 -28.28%, #f9dd7b 16.53%);
  box-shadow: 0px 25px 21px rgba(28, 23, 0, 0.3);
  border-radius: 25px;
  padding: 28px 34px 56px 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Volkhov";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 37px;
  color: #9b0c06;

  @media ${device.mobile} {
    padding: 28px 18px;
  }
`;

export const GreenBoxFake = styled.div`
  width: 100vw;
  height: 250px;
  background: linear-gradient(90deg, #1c7716 14.24%, #092607 204.27%);
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0px;

  @media ${device.tablet} {
    height: 200px;
  }
`;

export const GreenBoxFake2 = styled(GreenBoxFake)`
  width: 100vw;
  height: 500px;
  background: linear-gradient(90deg, #1c7716 14.24%, #092607 204.27%);
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0px;

  @media ${device.tablet} {
    height: 200px;
  }
`;

export const CaneLeft = styled.img`
  width: 100%;
  max-width: 165px;
  height: auto;
  transform: rotate(21.55deg);
  position: absolute;
  left: -120px;
  top: -170px;
  z-index: -1;

  @media ${device.tablet} {
    display: none;
  }
`;

export const CaneRight = styled(CaneLeft)`
  left: unset;
  right: -150px;
  top: calc(50% - 150px);
  transform: matrix(-0.94, 0.34, 0.34, 0.94, 0, 0);

  @media ${device.laptop} {
    right: -150px;
    left: unset;
  }
  @media ${device.tablet} {
    display: none;
  }
`;
