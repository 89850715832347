import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Content, DetailsContainer, DetailsImage } from "./styles";

import { DotsDivider } from "../../atoms/dots-devider/styles";
import NftDetailsEssential from "../../organisms/nft-details-essential";
import NftDetailsOrdinary from "../../organisms/nft-details-ordinary";
import NftDetailsStandard from "../../organisms/nft-details-standard";
import NftDetailsPremium from "../../organisms/nft-details-premium";
import NftDetailsJoker from "../../organisms/nft-joker";

import NftOrdinaryImageSrc from "../../img/assets/nft-ordinary.svg";
import { getAsset, getTime, getWindows } from "../../api";
import { FooterLongLine } from "../../molecules/footer/styles";

const DetailsPage = ({ signerPubKey }) => {
  const [windows, setWindows] = useState(null);
  const [asset, setAsset] = useState(null);
  const navigate = useNavigate();

  //Choose Details Component Type
  const ChooseDetailComponentType = (type) => {
    switch (type) {
      case "joker":
        return (
          <NftDetailsJoker
            detailsData={asset}
            isGolden={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 2
            }
            isClosed={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 0
            }
          />
        );
      case "essential":
        return (
          <NftDetailsEssential
            detailsData={asset}
            isGolden={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 2
            }
            isClosed={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 0
            }
          />
        );
      case "standard":
        return (
          <NftDetailsStandard
            detailsData={asset}
            isGolden={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 2
            }
            isClosed={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 0
            }
          />
        );
      case "premium":
        return (
          <NftDetailsPremium
            detailsData={asset}
            isGolden={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 2
            }
            isClosed={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 0
            }
          />
        );
      default:
        return (
          <NftDetailsOrdinary
            detailsData={asset.type ? asset : null}
            isGolden={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 2
            }
            isClosed={
              windows.days[
                parseInt(window.location.href.split("/dec-")[1]) - 1
              ] === 0
            }
          />
        );
    }
  };

  useEffect(() => {
    getTime().then((response) => {
      getWindows(signerPubKey).then((response) => {
        if (response.status === 200) {
          response.json().then(setWindows);
        } else {
          getTime().then((response) => {
            setWindows({
              currentTime: response,
              days: new Array(24).fill(0),
            });
          });
        }
      });
      getAsset(parseInt(window.location.href.split("/dec-")[1])).then(
        (response) => {
          response.json().then((info) => {
            setAsset(info);
          });
        }
      );
    });
  }, [signerPubKey, navigate]);

  if (!windows || !asset) return;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          chainlendar.io -{" "}
          {window.location.href.split("/dec-")[1].split("-")[0]} December
          {asset.name ? ` - ${asset.name}` : ""}
        </title>
      </Helmet>
      <DetailsContainer
        isGolden={
          windows.days[parseInt(window.location.href.split("/dec-")[1]) - 1] ===
          2
        }
      >
        <Content>
          {!asset.type ? (
            <DetailsImage src={NftOrdinaryImageSrc} alt="nutcracker image" />
          ) : (
            <DetailsImage
              src={
                windows.days[
                  parseInt(window.location.href.split("/dec-")[1]) - 1
                ] === 2
                  ? asset.imageGolden
                  : asset.image
              }
              alt="nutcracker image"
            />
          )}

          {ChooseDetailComponentType(asset.type)}
        </Content>
        <DotsDivider />
      </DetailsContainer>
      <FooterLongLine />
    </>
  );
};

export default DetailsPage;
