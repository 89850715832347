import styled from "styled-components";
import { device } from "../../utils/media-queries.js";

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  color: ${({ isGolden }) => (isGolden ? "#533A09" : "#F9DD7B")};
  flex-direction: column;
  background: ${({ isGolden }) =>
    isGolden
      ? "linear-gradient(320.05deg, #B68819 -20.74%, #584216 -1.44%, #B68819 16.57%, #F9DD7B 52.93%, #F9DD7B 59.65%, #BE9C32 82.85%)"
      : "radial-gradient(66.25% 50% at 50% 50%, #630000 0%, #3d0000 100%)"};
  gap: 80px;
  padding-top: 88px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const DetailsImage = styled.img`
  width: 100%;
  max-width: 440px;
  height: auto;
  position: relative;
  top: -25px;
  z-index: 0;
`;
