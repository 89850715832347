const { REACT_APP_MIDDLEWARE_URL, REACT_APP_TOTAL_NFTS_NUM } = process.env;

export const API_URL = REACT_APP_MIDDLEWARE_URL;
export const TOTAL_NFTS_NUM = REACT_APP_TOTAL_NFTS_NUM;
export const TABLET_BREAKPOINT = 1024;

//Nfts categories base array
export const NFT_CATEGORIES_ARRAY = [
  "Nutcrackers",
  "Hacker Nutcrackers",
  "Sponsor Nutcrackers",
  "Golden Nutcrackers",
];

//Internal Urls
export const URL_BASE_NUTCRACKER_2022 = "/c/nutcracker2022";
export const URL_FAQ = `/faq`;
export const URL_PROFILE = `/profile`;
export const URL_PRIVACY_POLICY = `/privacy-policy`;

//Social Urls
export const URL_BLOCKBITES = "https://blockbit.es";
export const URL_LINKEDIN = "https://www.linkedin.com/company/blockbites";
export const URL_TWITTER = "https://twitter.com/theblockbites";
export const URL_MEDIUM = "https://medium.com/@theblockbites";
export const URL_YOUTUBE = "https://www.youtube.com/@blockbites";
export const URL_TELEGRAM = "https://t.me/+7nneDXbzEgMxNDBk";

//Width breakpoints for nfts pagination
export const WIDTH_BREAKPOINT_PAGINATION_2_COLUMNS = 728;
export const WIDTH_BREAKPOINT_PAGINATION_3_COLUMNS = 1375;
