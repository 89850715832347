import styled from "styled-components";
import { device } from "../../utils/media-queries.js";

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  background: #ffffff;
  z-index: 9999;
`;

export const HeaderInnerContainer = styled.div`
  width: 100%;
  max-width: 1600px;
`;

export const Header = styled.header`
  width: 100%;
  height: 88px;
  opacity: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 124px;

  @media ${device.desktop} {
    padding: 0 72px;
  }

  @media ${device.laptop} {
    justify-content: center;
    padding: 0;
  }
`;

export const Logo = styled.img`
  width: auto;
`;

export const ConnectedForm = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ConnectedFormWallet = styled.img`
  width: 34px;
  cursor: pointer;
`;
export const ConnectedFormText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;

export const ConnectedFormButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito";
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  height: 48px;
  color: black;
  outline: none;
  border: none;
  text-decoration: none;
  margin-left: 8px;
  background: #ffffff;
  cursor: pointer;
`;
