import styled from "styled-components";
import { device } from "../../utils/media-queries.js";

export const CookiesWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
`;

export const Modal = styled.div`
  position: absolute;
  width: 100%;
  max-width: 358px;
  height: min-content;
  display: flex;
  flex-direction: column;
  box-shadow: 8px 9px 23px rgba(74, 0, 0, 0.43);
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  left: 36px;
  bottom: 36px;

  @media ${device.mobile} {
    width: calc(100% - 48px);
    margin: 24px;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 24px), calc(-50% - 24px));
  }
`;

export const Title = styled.div`
  font-family: "Volkhov";
  font-style: normal;
  max-width: 250px;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 12px;
`;

export const CookiesText = styled.div`
  font-family: "Volkhov";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const CookiesIllustration = styled.img`
  width: 100%;
  height: auto;
  margin-top: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PrivacyPolicyText = styled.div`
  color: black;
  display: inline-block;
  text-decoration: underline;
`;
