import styled from "styled-components";
import { device } from "../../utils/media-queries";

export const Spinner = styled.div`
  width: 100%;
  max-width: ${({ width }) => `${width}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: ${({ width, height }) => `${width} / ${height}`};
`;

export const TargetImage = styled.img`
  width: 100%;
  max-width: ${({ width }) => `${width}px`};
  height: auto;

  @media ${device.mobile} {
    max-width: unset;
  }
`;
