import React from "react";

import {
  Wrapper,
  MainContainer,
  FAQ,
  FAQLeftBlock,
  FAQRightBlock,
  VerticalDivider,
  StyledH1,
  StyledP,
  FAQTextBlock,
  FAQImage,
  MidContainer,
  LongLine,
} from "./styles";
import { DotsDivider } from "../../atoms/dots-devider/styles";

import calendarWindowUnknown from "../../img/assets/window_unknown.png";
import vectorDrum from "../../img/assets/drum.png";
import registerButton from "../../img/assets/register.png";
import barrellGold from "../../img/assets/barrel_gold.png";
import rewardNutcracker from "../../img/assets/nutcracker.png";
import nutcrackerGold from "../../img/assets/nutcracker_gold.png";
import calendarWindowMissed from "../../img/assets/window_missed.png";
import { FooterLongLine } from "../../molecules/footer/styles";

const Faq = () => {
  return (
    <Wrapper>
      <MainContainer>
        <MidContainer>
          <DotsDivider />
          <LongLine />
        </MidContainer>
        <FAQ reversed style={{ margin: "0 0 45px 0" }}>
          <FAQLeftBlock>
            <FAQTextBlock>
              <StyledH1>How to register?</StyledH1>
              <StyledP>
                First, you need to have CasperSigner installed and access the
                page via Chrome/Brave browser. We will ask you to sign a message
                (no tokens required) and will bind your email to your public
                key.
              </StyledP>
            </FAQTextBlock>
          </FAQLeftBlock>
          <VerticalDivider />
          <FAQRightBlock>
            <FAQImage
              src={registerButton}
              alt=""
              style={{ width: "258px", padding: "20px 0 0 0" }}
            />
          </FAQRightBlock>
        </FAQ>
        <FAQ>
          <FAQLeftBlock>
            <FAQImage src={calendarWindowUnknown} alt="" />
          </FAQLeftBlock>
          <VerticalDivider />
          <FAQRightBlock>
            <FAQTextBlock>
              <StyledH1>How to play?</StyledH1>
              <StyledP>
                Simply by participating in the challenge and opening the windows
                every day. <br />
                We have 24 windows waiting for you; each window is ready to be
                opened only on a specific day.
              </StyledP>
            </FAQTextBlock>
          </FAQRightBlock>
        </FAQ>
        <FAQ reversed>
          <FAQLeftBlock>
            <FAQTextBlock>
              <StyledH1>How much does it cost?</StyledH1>
              <StyledP>
                It's free! Casper Blockchain fees are covered by us and our
                sponsors.
              </StyledP>
            </FAQTextBlock>
          </FAQLeftBlock>
          <VerticalDivider />
          <FAQRightBlock>
            <FAQImage src={barrellGold} alt="" />
          </FAQRightBlock>
        </FAQ>
        <FAQ>
          <FAQLeftBlock>
            <FAQImage src={rewardNutcracker} alt="" />
          </FAQLeftBlock>
          <VerticalDivider />
          <FAQRightBlock>
            <FAQTextBlock>
              <StyledH1>Rewards</StyledH1>
              <StyledP>
                If you're persistent and open all windows, you will be awarded
                by guaranteed NFT at the end of this adventure.
              </StyledP>
            </FAQTextBlock>
          </FAQRightBlock>
        </FAQ>
        <FAQ reversed>
          <FAQLeftBlock>
            <FAQTextBlock>
              <StyledH1>What if I miss the window?</StyledH1>
              <StyledP>
                Do not worry - we will still include you on our list, and you
                will participate in a lottery to win the NFT. The more windows
                opened, the higher chance to win.
              </StyledP>
            </FAQTextBlock>
          </FAQLeftBlock>
          <VerticalDivider />
          <FAQRightBlock>
            <FAQImage src={calendarWindowMissed} alt="" />
          </FAQRightBlock>
        </FAQ>
        <FAQ>
          <FAQLeftBlock>
            <FAQImage src={vectorDrum} alt="" />
          </FAQLeftBlock>
          <VerticalDivider />
          <FAQRightBlock>
            <FAQTextBlock>
              <StyledH1>Feeling adventurous?</StyledH1>
              <StyledP>
                Check our Twitter account. Every day we will post the link to
                the chainlendar site, where we offer a limited chance to score a
                golden nutcracker. If you're fast enough, you will secure your
                prize. Ultimately, we will gratify the most active users with a
                special airdrop!
              </StyledP>
            </FAQTextBlock>
          </FAQRightBlock>
        </FAQ>
        <FAQ reversed style={{ marginBottom: "140px" }}>
          <FAQLeftBlock>
            <FAQTextBlock>
              <StyledH1>
                Do I need to wait for the chance to open golden nutcracker?
              </StyledH1>
              <StyledP>
                No need, open your window that day as usual. If you get lucky
                and click the golden nutcracker quickly enough, we will upgrade
                you!
              </StyledP>
            </FAQTextBlock>
          </FAQLeftBlock>
          <VerticalDivider />
          <FAQRightBlock>
            <FAQImage src={nutcrackerGold} alt="" />
          </FAQRightBlock>
        </FAQ>
        <DotsDivider />
        <FooterLongLine />
      </MainContainer>
    </Wrapper>
  );
};

export default Faq;
