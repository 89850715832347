import React from "react";
import NftCard from "../../atoms/nft-card";

import { Cards, ProfileCollectionWrapper, Title } from "./styles";

const ProfileCollection = ({ title, filteredData }) => {
  return (
    <ProfileCollectionWrapper>
      <Title>{title}</Title>
      <Cards>
        {filteredData.map((c, id) => (
          <NftCard key={id} data={c} />
        ))}
      </Cards>
    </ProfileCollectionWrapper>
  );
};

export default ProfileCollection;
