import styled from "styled-components";

import { device } from "../../utils/media-queries";

export const H4 = styled.h4`
  font-size: 48px;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  line-height: 120%;
  @media ${device.laptop} {
    font-size: 40px;
  }
  @media ${device.tablet} {
    font-size: 40px;
  }
  @media ${device.mobile} {
    font-size: 24px;
  }
`;