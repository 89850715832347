import React, { useEffect, useRef } from "react";

import { NftImage } from "./styles";

const VideoNft = ({ url, setVideoLoaded }) => {
  const videoRef = useRef();

  useEffect(() => {
    setVideoLoaded(false);
    videoRef.current?.load();
  }, [url]);

  return (
    <NftImage>
      <video
        width={"100%"}
        ref={videoRef}
        controls={false}
        loop
        autoPlay
        onLoadedData={() => setVideoLoaded(true)}
      >
        <source src={url} />
      </video>
    </NftImage>
  );
};

export default VideoNft;
