import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { SignerConnector } from "./components/signer";
import { getUser, getNFTs } from "./api";

import CookiesModal from "./molecules/cookies-modal";
import PrivacyPolicy from "./organisms/privacy-policy";
import Navbar from "./molecules/navbar";
import Footer from "./molecules/footer";
import Calendar from "./organisms/calendar";
import Banner from "./molecules/banner";
import Faq from "./organisms/faq";
import Profile from "./organisms/profile";
import DetailsPage from "./templates/details-page";
import { getAssets } from "./api";
import CategoriesHomepage from "./organisms/categories-homepage";
import CategorySubpage from "./organisms/category-subpage";
import NftCardDetailsPage from "./templates/nft-card-details-page";
import {
  URL_BASE_NUTCRACKER_2022,
  URL_FAQ,
  URL_PRIVACY_POLICY,
  URL_PROFILE,
  NFT_CATEGORIES_ARRAY,
} from "./constants";

import ProfilePage from "./templates/profile-page";

const dayRoute = [...Array(24).keys()];

function App() {
  const [assets, setAssets] = useState(null);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [profileModalOpened, setProfileModalOpened] = useState(false);
  const [signerPubKey, setPubKey] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [isUserRegistered, setIsUserRegistered] = useState(false);

  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    getNFTs().then((data) => {
      setNfts(data);
    });
  }, []);

  //Cookies
  useEffect(() => {
    getAssets().then((response) => {
      response.json().then((info) => {
        setAssets(info);
      });
    });
    if (localStorage.getItem("cookies-accept")) {
      setCookiesAccepted(true);
    } else {
      setCookiesAccepted(false);
    }
  }, []);

  //SetSigner
  const setSignerPubKey = (publicKey) => {
    if (publicKey) {
      setPubKey(publicKey);
    } else {
      setPubKey(null);
    }
  };

  //Check if user registered
  useEffect(() => {
    getUser(signerPubKey).then((response) => {
      if (response.status === 200) {
        setIsUserRegistered(true);
      } else {
        setIsUserRegistered(false);
      }
    });
  }, [signerPubKey]);

  //Window size listener
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  if (!assets) return;
  if (!nfts) return;

  return (
    <>
      {cookiesAccepted ? null : (
        <CookiesModal setCookiesAccepted={setCookiesAccepted} />
      )}

      {profileModalOpened ? (
        <Profile
          signerPubKey={signerPubKey}
          setProfileModalOpened={setProfileModalOpened}
        />
      ) : null}
      <Navbar
        signerPubKey={signerPubKey}
        setProfileModalOpened={setProfileModalOpened}
      />
      <SignerConnector setSignerPubKey={setSignerPubKey} />
      <Routes>
        <Route path="/" exact element={<CategoriesHomepage />} />
        <Route
          path={URL_BASE_NUTCRACKER_2022}
          exact
          element={
            <>
              <Banner />
              <Calendar signerPubKey={signerPubKey} />
            </>
          }
        />
        <Route
          path={URL_FAQ}
          exact
          element={
            <>
              <Banner isFaq />
              <Faq />
            </>
          }
        />
        {/* Nft Categories */}
        {NFT_CATEGORIES_ARRAY.map((category, id) => (
          <Route
            key={id}
            path={`/cat-${category.toLocaleLowerCase().replace(" ", "-")}`}
            exact
            element={
              <CategorySubpage
                title={category}
                ballImg={`/assets/category-ball/cat-banner-ball-${category
                  .toLocaleLowerCase()
                  .replace(" ", "-")}.svg`}
                nfts={nfts.filter((nft) => nft.meta.category === id.toString())}
                width={width}
              />
            }
          />
        ))}
        {/* Nft details page */}
        <Route
          path="/nft-details/:id"
          exact
          element={
            <NftCardDetailsPage
              nfts={nfts}
              categories={NFT_CATEGORIES_ARRAY}
              signerPubKey={signerPubKey}
            />
          }
        />

        {/* Profile page */}
        <Route
          path={URL_PROFILE}
          exact
          element={
            isUserRegistered ? <ProfilePage signerPubKey={signerPubKey} /> : <Navigate to="/" replace />
          }
        />

        <Route path={URL_PRIVACY_POLICY} exact element={<PrivacyPolicy />} />

        {/* Dynamic Route for every day */}
        {dayRoute.map((day) => {
          const i = assets?.findIndex((e) => e.dayNumber === day + 1);
          return (
            <Route
              key={day}
              path={
                assets[i]?.name
                  ? `${URL_BASE_NUTCRACKER_2022}/dec-${day + 1}-${assets[i].name
                      .replace(/\W/g, "")
                      .toLowerCase()}`
                  : `${URL_BASE_NUTCRACKER_2022}/dec-${day + 1}`
              }
              exact
              element={<DetailsPage signerPubKey={signerPubKey} />}
            />
          );
        })}

        {/* Redirect if no page */}
        <Route path="*" element={<Navigate to="/" replace />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
