import styled from "styled-components";
import { device } from "../../utils/media-queries.js";

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 620px;
  flex-direction: column;
  font-weight: 700;
  margin-top: ${({ sponsored }) => (sponsored ? "230px" : "175px")};
  @media ${device.laptop} {
    margin: 0 10px;
    width: auto;
  }
`;
export const SecTextContainer = styled(TextContainer)`
  margin-top: 20px;
`;

export const DetailsTitle = styled.h2`
  width: 100%;
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
`;

export const TextDesc = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
`;

export const SubTitle = styled.p`
  max-width: 564px;
  width: 100%;
  font-size: 24px;
  line-height: 31px;
  margin: 14px 0 12px 0;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 36px;
  margin-bottom: 28px;
  @media ${device.laptop} {
    gap: 16px;
  }
`;

export const ExtraPrizeTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  margin: 24px 0 12px 0;
`;

export const ExtraPrizeText = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
`;

export const GoldenText = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-top: 4px;
`;
