import React from "react";

import ImageLoader from "../../molecules/image-loader";
import { Banner, BannerTextWrapper, LogoText, NCBLogo } from "./styles";

import BannerImageSrc from "../../img/assets/profile-banner.svg";

const BannerProfile = () => {
  return (
    <Banner>
      <BannerTextWrapper>
        <NCBLogo />
        <LogoText>Welcome to your collections</LogoText>
      </BannerTextWrapper>
      <ImageLoader width={518} height={550} image={BannerImageSrc} />
    </Banner>
  );
};

export default BannerProfile;
