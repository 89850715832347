import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

import {
  Banner,
  BannerTextWrapper,
  CategoriesHomepageWrapper,
  LogoText,
  NCBLogo,
  LogoCtaContainer,
  LottieStyled,
} from "./styles";

import NCBallBannerSrc from "../../img/assets/nc-ball-banner.svg";
import CategoriesWindow from "../../molecules/categories-window";
import CtaAnim from "../../anim/cta-anim.json";
import ImageLoader from "../../molecules/image-loader";

const CategoriesHomepage = () => {
  return (
    <CategoriesHomepageWrapper>
      <Banner>
        <BannerTextWrapper>
          <LogoCtaContainer>
            <Link to="/c/nutcracker2022">
              <NCBLogo />
            </Link>
            <LottieStyled as={Lottie} animationData={CtaAnim} loop autoplay />
          </LogoCtaContainer>
          <LogoText>Visit the Nutcracker Advent Calendar !!!</LogoText>
        </BannerTextWrapper>
        <ImageLoader width={581} height={608} image={NCBallBannerSrc} />
      </Banner>
      <CategoriesWindow />
    </CategoriesHomepageWrapper>
  );
};

export default CategoriesHomepage;
