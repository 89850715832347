import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";

import { CLPublicKey } from "casper-js-sdk";
import { getNFTOwner } from "../../api";
import CategoryBanner from "../../molecules/category-banner";
import NftInfoBox from "../../molecules/nft-info-box";

import {
  GreenBoxFake,
  NftCardDetailsPageWrapper,
  ContentContainer,
  PrevBtn,
  NextBtn,
  CaneLeft,
  CaneRight,
  ImgContainer,
} from "./styles";

import CaneSrc from "../../img/assets/cane-img.svg";
import LeftArrowSrc from "../../img/assets/left-arrow.svg";
import RightArrowSrc from "../../img/assets/right-arrow.svg";
import VideoNft from "../../atoms/video-nft";
import VideoLoader from "../../molecules/video-loader";

const NftCardDetailsPage = ({ nfts, categories, signerPubKey }) => {
  const [owner, setOwner] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getNFTOwner(id).then(setOwner);
  }, [id]);

  const checkWhoOwnNft = () => {
    if (signerPubKey) {
      const pk = CLPublicKey.fromHex(signerPubKey);
      const accountHash = pk.toAccountHashStr();
      return accountHash === owner;
    } else {
      return false;
    }
  };

  if (!nfts) return null;

  //Find selected nft
  const selectedNftIndex = nfts.findIndex((n) => n && n.id === id);
  const selectedNft = nfts[selectedNftIndex];

  if (!selectedNft) return null;

  //Find nft category name
  const categoryName = categories
    .filter((c, id) => id.toString() === selectedNft.meta.category)
    .toString();

  //Find nft category length
  const activeCategoryNfts = nfts.filter(
    (n) => n.meta.category === selectedNft.meta.category
  );

  //Check for disabling nav btn
  const isFirstInCategory = () => {
    return selectedNft.id === activeCategoryNfts[0].id;
  };
  const isLastInCategory = () => {
    return (
      selectedNft.id === activeCategoryNfts[activeCategoryNfts.length - 1].id
    );
  };

  //Selected nft category banner ball image
  const categoryBannerBall = categoryName.toLowerCase().replace(" ", "-");

  //Next nft
  const nextNftId = () => {
    const maybeNext = nfts[selectedNftIndex + 1];
    return maybeNext ? maybeNext?.id : nfts[0].id;
  };

  //Prev nft
  const prevNftId = () => {
    const maybePrev = nfts[selectedNftIndex - 1];
    return maybePrev ? maybePrev?.id : nfts[nfts.length - 1].id;
  };

  //Arrows navigations
  const NavigationFunction = () => {
    const handleArrowLeft = () => {
      if (!isFirstInCategory()) {
        navigate(`/nft-details/${prevNftId()}`);
      }
    };

    const handleArrowRight = () => {
      if (!isLastInCategory()) {
        navigate(`/nft-details/${nextNftId()}`);
      }
    };

    const useKey = (key, cb) => {
      const callbackRef = useRef(cb);

      useEffect(() => {
        callbackRef.current = cb;
      });
      useEffect(() => {
        const handle = (event) => {
          if (event.code === key) {
            callbackRef.current(event);
          }
        };
        document.addEventListener("keydown", handle);
        return () => document.removeEventListener("keydown", handle);
      }, [key]);
    };

    useKey("ArrowLeft", handleArrowLeft);
    useKey("ArrowRight", handleArrowRight);
  };

  return (
    <NftCardDetailsPageWrapper>
      <NavigationFunction />
      <CategoryBanner
        title={categoryName}
        ballImg={`/assets/category-ball/cat-banner-ball-${categoryBannerBall}.svg`}
      />
      <ContentContainer>
        {activeCategoryNfts.length === 1 ? (
          <VideoNft url={`${selectedNft.meta.asset}.mp4`} />
        ) : (
          <ImgContainer>
            <PrevBtn
              disabled={isFirstInCategory()}
              as={Link}
              to={`/nft-details/${prevNftId()}`}
            >
              <img src={RightArrowSrc} alt="" />
            </PrevBtn>
            <VideoLoader
              url={`${selectedNft.meta.asset}.mp4`}
              width={820}
              height={820}
            />
            <NextBtn
              disabled={isLastInCategory()}
              as={Link}
              to={`/nft-details/${nextNftId()}`}
            >
              <img src={LeftArrowSrc} alt="" />
            </NextBtn>
          </ImgContainer>
        )}
        <NftInfoBox
          categoryBannerBall={categoryBannerBall}
          activeCategoryNfts={activeCategoryNfts}
          selectedNft={{ ...selectedNft, owner }}
          categoryName={categoryName}
          checkWhoOwnNft={checkWhoOwnNft}
        />
        <CaneLeft src={CaneSrc} />
        <CaneRight src={CaneSrc} />
      </ContentContainer>
      <GreenBoxFake />
    </NftCardDetailsPageWrapper>
  );
};

export default NftCardDetailsPage;
