import styled from "styled-components";

import GoToFaqSrc from "../../img/assets/cta-check-rules.svg";
import GoToFaqHoverSrc from "../../img/assets/cta-check-rules-hover.svg";
import GoToCalendarSrc from "../../img/assets/cta-calendar.svg";
import GoToCalendarHoverSrc from "../../img/assets/cta-calendar-hover.svg";

import { device } from "../../utils/media-queries.js";

export const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(105.35deg, #fffaf3 27.65%, #ffe8cc 139.5%);
  padding-top: 88px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
  padding-left: 150px;
  overflow-x: hidden;

  @media ${device.laptop} {
    padding: 0;
  }
`;

export const NutCrackerImage = styled.img`
  width: 100%;
  max-width: 664px;
  height: auto;
  align-self: flex-end;

  @media ${device.laptop} {
    max-width: 464px;
    display: none;
  }
`;

export const NutCrackerImageMobile = styled.img`
  width: 100%;
  height: auto;
  display: none;
  max-width: 300px;
  margin: 20px 0;

  @media ${device.laptop} {
    display: block;
  }

  @media ${device.tablet} {
    max-width: 250px;
  }
  @media ${device.mobile} {
    max-width: 205px;
  }
`;

export const Subtitle = styled.p`
  width: 100%;
  max-width: 760px;
  font-style: italic;
  font-weight: 700;
  font-size: 47.71px;
  line-height: 62px;
  text-align: center;
  margin: 25px 0 55px 0;

  @media ${device.laptop} {
    margin: 24px 0 12px 0;
    padding: 0 40px;
  }

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 41px;
    margin: 24px 0 12px 0;
    padding: 0 20px;
  }
`;

export const SubtitleFAQ = styled(Subtitle)`
  font-size: 31px;
  line-height: 40px;
  margin: 40px 0 6px 0;

  @media ${device.tablet} {
    margin: 24px 0 30px 0;
  }

  @media ${device.mobile} {
    font-size: 21px;
    line-height: 27px;
  }
`;

export const MainLogo = styled.img`
  width: 100%;
  max-width: 806px;
  height: auto;
  min-width: 540px;

  @media ${device.laptop} {
    min-width: unset;
    padding: 0 40px;
  }

  @media ${device.mobile} {
    min-width: unset;
    padding: 0 16px;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 112px;
  max-width: 760px;

  @media ${device.desktop} {
    margin-top: 102px;
  }
  @media ${device.laptop} {
    margin-top: 0;
  }
`;

export const Cta = styled.div`
  width: 100%;
  max-width: 655px;
  height: 250px;
  background: url(${GoToFaqSrc}) no-repeat center;
  transition: all 0.3s;

  @media ${device.mobile} {
    height: calc(0.3816 * 100vw);
    background-size: 100%;
  }

  &:hover {
    background-image: url(${GoToFaqHoverSrc});
  }

  &:active {
    background-image: url(${GoToFaqSrc});
    transform: translateY(8px) scale(0.97);
  }

  /* Preloading hover image */
  &::before {
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(${GoToFaqHoverSrc});
    content: "";
  }
`;

export const CtaFaq = styled(Cta)`
  background: url(${GoToCalendarSrc}) no-repeat center;

  &:hover {
    background-image: url(${GoToCalendarHoverSrc});
  }

  &:active {
    background-image: url(${GoToCalendarSrc});
  }

  /* Preloading hover image */
  &::before {
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(${GoToCalendarHoverSrc});
    content: "";
  }
`;
