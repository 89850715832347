import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../image-loader";

import {
  BannerBtn,
  BannerTextWrapper,
  CategoryBannerWrapper,
  CategoryName,
  Subtitle,
} from "./styles";

const CategoryBanner = ({ title, ballImg }) => {
  return (
    <CategoryBannerWrapper>
      <BannerTextWrapper>
        <CategoryName>{title}</CategoryName>
        <Subtitle>
          The ordinary nutcrackers we would love to lorem ipsum.
        </Subtitle>
        <BannerBtn as={Link} to={"/"}>
          Get me back to the all categories
        </BannerBtn>
      </BannerTextWrapper>
      <ImageLoader width={581} height={572} image={ballImg} />
    </CategoryBannerWrapper>
  );
};

export default CategoryBanner;
