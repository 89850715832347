import React from "react";

import { ArrowBtn } from "../../atoms/arrow-btn/styles";
import { PageInfo, PaginationContainer } from "./styles";

import LeftArrowSrc from "../../img/assets/left-arrow.svg";
import RightArrowSrc from "../../img/assets/right-arrow.svg";

const Pagination = ({ currentPage, maxPageNumber, nextPage, prevPage }) => {
  return (
    <PaginationContainer>
      <ArrowBtn disabled={false} onClick={() => prevPage(currentPage)}>
        <img src={RightArrowSrc} alt="" />
      </ArrowBtn>
      <PageInfo>
        {currentPage} of {maxPageNumber}
      </PageInfo>
      <ArrowBtn disabled={false} onClick={() => nextPage(currentPage)}>
        <img src={LeftArrowSrc} alt="" />
      </ArrowBtn>
    </PaginationContainer>
  );
};

export default Pagination;
