import styled from "styled-components";
import { device } from "../../utils/media-queries";

import NCLogoSrc from "../../img/assets/nutcracker-logo.svg";
import NCLogoHoverSrc from "../../img/assets/nutcracker-logo-hover.svg";

export const CategoriesHomepageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(360deg, #ff0000 89.2%, #b00000 107.67%);
  padding: 0 48px;
  overflow-x: hidden;
  margin-top: 88px;

  @media ${device.tablet} {
    padding: 0 24px;
  }

  @media ${device.mobile} {
    padding: 0 16px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;

  @media ${device.laptop} {
    flex-direction: column-reverse;
    padding-top: 15px;
    gap: 16px;
  }
`;

export const NCBLogo = styled.div`
  width: 100%;
  max-width: 467px;
  background: url(${NCLogoSrc}) no-repeat center;
  transition: 0.1s;
  height: 0;
  padding-top: 36.4%;
  background-size: contain;

  &:hover {
    background: url(${NCLogoHoverSrc}) no-repeat center;
    background-size: contain;
  }

  /* Preloading hover image */
  &::before {
    background: url(${NCLogoHoverSrc}) no-repeat center;
    position: absolute;
    width: 0;
    height: 0;
    content: "";
  }
`;

export const BannerTextWrapper = styled.div`
  width: 100%;
  max-width: 467px;
  flex-basis: 467px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  color: #fadd7d;
  font-family: "Volkhov";
  font-style: italic;
  font-weight: 700;
  font-size: 32.8031px;
  line-height: 42px;
`;

export const LogoText = styled.div`
  width: 100%;
  max-width: 400px;
  color: #fadd7d;
  font-family: "Volkhov";
  font-style: italic;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  text-align: center;

  @media ${device.tablet} {
    font-size: 26px;
    line-height: 34px;
    max-width: 300px;
  }
`;

export const LogoCtaContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const LottieStyled = styled.div`
  position: absolute;
  width: 100px;
  bottom: -65px;
  right: -15px;

  @media ${device.tablet} {
    width: 85px;
  }

  @media ${device.mobile} {
    width: 70px;
  }
`;

export const HoverPreloadingImg = styled.div`
  background: url(${NCLogoHoverSrc}) no-repeat center;
`;
