import styled from "styled-components";
import { device } from "../../utils/media-queries";

export const PagePositioner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: linear-gradient(360deg, #ff0000 89.2%, #b00000 107.67%);
  overflow: hidden;
  padding: 45px 48px 0 48px;
  margin-top: 88px;
`;

export const NftsContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 36px 16px;
  z-index: 1;
  position: relative;
  margin-bottom: 40px;
  margin-top: 64px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const CaneLeft = styled.img`
  width: 100%;
  max-width: 165px;
  height: auto;
  transform: rotate(21.55deg);
  position: absolute;
  left: -100px;
  top: -135px;
  z-index: -99;

  @media ${device.laptop} {
    left: -20px;
    top: -60px;
  }

  @media ${device.tablet} {
    left: -70px;
    top: -50px;
  }

  @media ${device.mobile} {
    left: -130px;
    top: -50px;
  }
`;

export const CaneRight = styled(CaneLeft)`
  left: unset;
  z-index: 2;
  right: -150px;
  top: calc(50% - 150px);
  transform: matrix(-0.94, 0.34, 0.34, 0.94, 0, 0);

  @media ${device.laptop} {
    right: -150px;
    left: unset;
  }
`;

export const CaneBottom = styled(CaneLeft)`
  top: unset;
  bottom: -300px;
  left: calc(50vw - 400px);
  transform: rotate(-20.63deg);
  z-index: 3;

  @media ${device.laptop} {
    left: 50px;
  }

  @media ${device.tablet} {
    left: 0;
  }
`;

export const GreenBoxFake = styled.div`
  width: 100vw;
  height: 220px;
  background: linear-gradient(90deg, #1c7716 14.24%, #092607 204.27%);
  position: absolute;
  bottom: 0px;
`;
