import styled from "styled-components";
import { PrimaryBtn } from "../../atoms/primary-btn/styles";
import { device } from "../../utils/media-queries";

export const CategoryBannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 68px;

  @media ${device.desktop} {
    gap: 32px;
  }

  @media ${device.laptop} {
    flex-direction: column-reverse;
    padding-top: 15px;
    gap: 16px;
  }
`;

export const BallImage = styled.img`
  width: 100%;
  max-width: 580px;
  height: auto;
`;

export const CategoryName = styled.div`
  font-family: "Volkhov";
  font-style: normal;
  font-weight: 700;
  font-size: 68.4615px;
  line-height: 88px;
  text-align: center;
  color: #fadd7b;
  text-shadow: 0px 4px 0px #690000;

  @media ${device.tablet} {
    font-size: 52px;
    line-height: 69px;
  }
`;

export const Subtitle = styled.div`
  font-family: "Volkhov";
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;

  @media ${device.tablet} {
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 28px;
  }

  @media ${device.mobile} {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const BannerTextWrapper = styled.div`
  width: 100%;
  max-width: 411px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  color: #fadd7d;
  font-family: "Volkhov";
  font-style: italic;
  font-weight: 700;
  font-size: 32.8031px;
  line-height: 42px;

  @media ${device.tablet} {
    gap: 0px;
  }
`;

export const LogoText = styled.div`
  width: 100%;
  max-width: 300px;
  color: #fadd7d;
  font-family: "Volkhov";
  font-style: italic;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  text-align: center;

  @media ${device.tablet} {
    font-size: 26px;
    line-height: 34px;
  }
`;

export const BannerBtn = styled(PrimaryBtn)`
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding: 14px 16px;
`;
