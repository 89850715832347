import React from "react";

import {
  DetailsTitle,
  ExtraPrizeText,
  ExtraPrizeTitle,
  GoldenText,
  IconsContainer,
  SecTextContainer,
  SubTitle,
  TextContainer,
  TextDesc,
} from "./styles";

import {
  BsTwitter,
  BsMedium,
  BsYoutube,
  BsLinkedin,
  BsTelegram,
  BsDiscord,
} from "react-icons/bs";
import { TbWorld } from "react-icons/tb";

const NftDetailsPremium = ({ isGolden, detailsData, isClosed }) => {
  return (
    <TextContainer>
      {(() => {
        if (
          detailsData.links.twitter ||
          detailsData.links.medium ||
          detailsData.links.youtube ||
          detailsData.links.linkedin ||
          detailsData.links.telegram ||
          detailsData.links.www ||
          detailsData.links.discord
        ) {
          return (
            <IconsContainer>
              {detailsData.links.twitter ? (
                <a
                  href={detailsData.links.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsTwitter
                    size={34}
                    color={!isGolden ? "#F9DD7B" : "#533A09"}
                  />
                </a>
              ) : null}
              {detailsData.links.www ? (
                <a
                  href={detailsData.links.www}
                  target="_blank"
                  rel="noreferrer"
                >
                  <TbWorld
                    size={34}
                    color={!isGolden ? "#F9DD7B" : "#533A09"}
                  />
                </a>
              ) : null}
              {detailsData.links.medium ? (
                <a
                  href={detailsData.links.medium}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsMedium
                    size={34}
                    color={!isGolden ? "#F9DD7B" : "#533A09"}
                  />
                </a>
              ) : null}
              {detailsData.links.youtube ? (
                <a
                  href={detailsData.links.youtube}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsYoutube
                    size={34}
                    color={!isGolden ? "#F9DD7B" : "#533A09"}
                  />
                </a>
              ) : null}
              {detailsData.links.linkedin ? (
                <a
                  href={detailsData.links.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsLinkedin
                    size={34}
                    color={!isGolden ? "#F9DD7B" : "#533A09"}
                  />
                </a>
              ) : null}
              {detailsData.links.telegram ? (
                <a
                  href={detailsData.links.telegram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsTelegram
                    size={34}
                    color={!isGolden ? "#F9DD7B" : "#533A09"}
                  />
                </a>
              ) : null}
              {detailsData.links.discord ? (
                <a
                  href={detailsData.links.discord}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsDiscord
                    size={34}
                    color={!isGolden ? "#F9DD7B" : "#533A09"}
                  />
                </a>
              ) : null}
            </IconsContainer>
          );
        }
      })()}
      {isGolden ? (
        <>
          <DetailsTitle>
            Golden Nutcracker salutes you, Master of Reflex.
          </DetailsTitle>
          <GoldenText>
            Golden Nutcracker lets you win the blockbit.es prize for the most
            active users! Every Golden one increases your chances!
          </GoldenText>
        </>
      ) : isClosed ? (
        <DetailsTitle>
          Check what we’ve prepared in today’s window!
        </DetailsTitle>
      ) : (
        <DetailsTitle>Thank you for opening today’s window!</DetailsTitle>
      )}
      <SecTextContainer>
        <SubTitle>Today’s sponsor is {detailsData.name}!</SubTitle>
        <TextDesc>{detailsData.description}</TextDesc>
      </SecTextContainer>
      {detailsData.extraPrizeDescription === "" || !isGolden ? null : (
        <>
          <ExtraPrizeTitle>Extra Prize!</ExtraPrizeTitle>
          <ExtraPrizeText>{detailsData.extraPrizeDescription}</ExtraPrizeText>
        </>
      )}
    </TextContainer>
  );
};

export default NftDetailsPremium;
