import styled from "styled-components";
import { device } from "../../utils/media-queries";

export const ProfileCollectionWrapper = styled.div`
  width: 100%;
  min-height: 470px;
  background: linear-gradient(180deg, #b68819 -28.28%, #f9dd7b 16.53%);
  box-shadow: 0px 25px 21px rgba(28, 23, 0, 0.3);
  border-radius: 25px;
  padding: 28px 34px 56px 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.mobile} {
    padding: 28px 18px;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-family: "Volkhov";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 37px;
  color: #9b0c06;
  border-bottom: 2px solid #9b0c06;
  padding-bottom: 8px;

  @media ${device.mobile} {
    font-size: 24px;
    line-height: 31px;
    text-align: center;
  }
`;

export const Cards = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 18px;
  margin-top: 28px;

  @media ${device.desktop} {
    justify-content: center;
  }
  @media ${device.mobile} {
    gap: 28px;
  }
`;
