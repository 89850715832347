import styled from "styled-components";

export const ArrowBtn = styled.button`
  width: 30px;
  height: 32px;
  background-color: ${({ disabled }) => (disabled ? "grey" : "black")};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
