import { API_URL } from "./constants";

export const getWindows = (pubKey) =>
  fetch(`${API_URL}/me/calendar?pubKey=${pubKey}`).then((resp) => resp);

export const getUser = (pubKey) =>
  fetch(`${API_URL}/me?pubKey=${pubKey}`).then((resp) => resp);

export const getTime = () =>
  fetch(`${API_URL}/time`).then((resp) => resp.json());

export const registerUser = (email, pubKey, signedMessage, token) =>
  fetch(API_URL + "/register", {
    method: "POST",
    body: JSON.stringify({
      email,
      pubKey,
      signedMessage,
      token,
    }),
    headers: { "Content-Type": "application/json" },
  }).then((resp) => resp);

export const updateUser = (email, signedMessage, token) =>
  fetch(API_URL + "/me/change", {
    method: "POST",
    body: JSON.stringify({
      signedMessage,
      email,
      token,
    }),
    headers: { "Content-Type": "application/json" },
  }).then((resp) => resp);

export const openWindow = (signedMessage, refCode, pubKey, token) =>
  fetch(API_URL + "/me/calendar", {
    method: "POST",
    body: JSON.stringify({
      signedMessage,
      refCode,
      pubKey,
      token,
    }),
    headers: { "Content-Type": "application/json" },
  }).then((resp) => resp);

export const getAssets = () => fetch(`${API_URL}/asset`).then((resp) => resp);

export const getAsset = (day) =>
  fetch(`${API_URL}/asset?day=${day}`).then((resp) => resp);

export const getNFTs = () =>
  fetch(`${API_URL}/minted`).then((resp) => resp).then(resp => resp.json());

export const getNFTOwner = (id) =>
  fetch(`${API_URL}/owner/${id}`).then((resp) => resp).then(resp => resp.text());

export const getOwned = (accountHash) =>
  fetch(`${API_URL}/owned/${accountHash}`).then((resp) => resp).then(resp => resp.json());
