import React, { useEffect, useState } from "react";

import { Signer } from "casper-js-sdk";
import { registerUser, getUser, updateUser } from "../../api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  AgreeText,
  CheckBox,
  CloseIcon,
  InfoText,
  Modal,
  ProfileModalWrapper,
  Title,
  Form,
  Input,
  Label,
  InputsContainer,
  ErroMessage,
  WindowContainer,
  WindowImage,
  CountersContainer,
  CounterText,
  CounterTextBold,
  LineImage,
} from "./styles";

import CloseIconSrc from "../../img/assets/close-icon.svg";
import { PrimaryBtn } from "../../atoms/primary-btn/styles";
import WindowBasic from "../../img/assets/prof-window.svg";
import WindowGold from "../../img/assets/prof-window-gold.svg";
import WindowJoker from "../../img/assets/prof-window-joker.svg";
import LineSeparator from "../../img/assets/prof-line.svg";

const Profile = ({ signerPubKey, setProfileModalOpened }) => {
  const [registered, setRegistered] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [termsError, setTermsError] = useState(false);
  const [terms, setTerms] = useState(false);
  const [counters, setCounters] = useState(null);
  const [error, setError] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const { executeRecaptcha } = useGoogleReCaptcha();

  //Check if user registered
  useEffect(() => {
    getUser(signerPubKey).then((response) => {
      if (response.status === 200) {
        setRegistered(true);
        setTerms(true);
        response.json().then((counters) => {
          setCounters(counters.message);
        });
      } else {
        setRegistered(false);
      }
    });
  }, [signerPubKey]);

  const handleSubmit = (event) => {
    event.preventDefault();
    executeRecaptcha().then((token) => {
      if (!registered) {
        Signer.signMessage("Merry christmas!", signerPubKey).then(
          (signedMessage) => {
            registerUser(email, signerPubKey, signedMessage, token).then(
              (response) => {
                if (response.status === 200) {
                  setProfileModalOpened(false);
                  window.location.reload(false);
                } else {
                  setEmailError(true);
                  response.json().then((e) => {
                    setError(e.message);
                  });
                }
              }
            );
          }
        );
      } else {
        Signer.signMessage("Merry christmas!", signerPubKey).then(
          (signedMessage) => {
            updateUser(email, signedMessage, token).then((response) => {
              if (response.status === 200) {
                setProfileModalOpened(false);
                window.location.reload(false);
              } else {
                setEmailError(true);
                response.json().then((e) => {
                  setError(e.message);
                });
              }
            });
          }
        );
      }
    });
  };

  //Validation
  const validateEmail = () => {
    email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ? setEmailError(false)
      : setEmailError(true);
  };

  const validateTerms = () => {
    terms ? setTermsError(false) : setTermsError(true);
  };

  useEffect(() => {
    if (email.length !== 0) {
      validateEmail();
    }
    validateTerms();
    // eslint-disable-next-line
  }, [email, terms]);

  useEffect(() => {
    if (emailError || termsError) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
    if (email.length === 0) {
      setBtnDisable(true);
    }
    // eslint-disable-next-line
  }, [emailError, termsError]);

  if (registered === null) return;

  return (
    <ProfileModalWrapper>
      <Modal>
        <CloseIcon
          src={CloseIconSrc}
          alt="close icon"
          onClick={() => setProfileModalOpened(false)}
        />
        <Title> {registered ? "Profile" : "Register"}</Title>
        {registered ? (
          <>
            <WindowContainer>
              <WindowImage src={WindowBasic} alt="window basic" />
              <WindowImage src={WindowGold} alt="window gold" />
              <WindowImage src={WindowJoker} alt="window jokers" />
            </WindowContainer>
            <CountersContainer>
              <CounterText>
                <CounterTextBold>{counters?.opened || 0}/24</CounterTextBold>
                <br />
                windows open
              </CounterText>

              <CounterText>
                <CounterTextBold>{counters?.gold || 0} gold</CounterTextBold>
                <br />
                Nutcrackers
              </CounterText>
              <CounterText>
                <CounterTextBold>{counters?.jokers || 0}</CounterTextBold>
                <br />
                Jokers
              </CounterText>
            </CountersContainer>
            <LineImage src={LineSeparator} alt="line separator" />
          </>
        ) : null}
        {registered ? (
          <InfoText>Please enter the new email and apply the changes.</InfoText>
        ) : (
          <InfoText>
            Please complete your registration.
            <br /> After clicking “Register” Casper Signer window will appear to
            confirm your action.
          </InfoText>
        )}

        <Form onSubmit={handleSubmit}>
          <InputsContainer>
            <Label>
              E-Mail:
              <Input
                type="email"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                emailError={emailError}
              />
              <ErroMessage>{error}</ErroMessage>
            </Label>
            <Label>
              Public Key:
              <Input
                type="text"
                name="pubKey"
                readOnly={true}
                value={signerPubKey}
              />
            </Label>
          </InputsContainer>
          <PrimaryBtn type="submit" disabled={btnDisable}>
            {registered ? "Apply changes" : "Register"}
          </PrimaryBtn>
          {registered ? null : (
            <CheckBox>
              <input
                id="check"
                type="checkbox"
                checked={terms}
                onClick={() => setTerms(!terms)}
              />
              <AgreeText for="check">
                by clicking you agree to our terms.
              </AgreeText>
            </CheckBox>
          )}
        </Form>
      </Modal>
    </ProfileModalWrapper>
  );
};

export default Profile;
