import styled from "styled-components";
import { device } from "../../utils/media-queries";

export const PaginationContainer = styled.div`
  display: flex;
  justify-items: center;
  gap: 20px;
  z-index: 2;
  @media ${device.tablet} {
    margin-bottom: 24px;
  }
`;

export const PageInfo = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: white;
  background-color: black;
  padding: 6px 12px;
  border-radius: 10px;
`;
