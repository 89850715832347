import React from "react";
import { Link } from "react-router-dom";

import {
  BannerWrapper,
  Column,
  Content,
  Cta,
  CtaFaq,
  MainLogo,
  NutCrackerImage,
  NutCrackerImageMobile,
  Subtitle,
  SubtitleFAQ,
} from "./styles";
import { URL_BASE_NUTCRACKER_2022, URL_FAQ } from "../../constants";

import NutCrackerImageSrc from "../../img/assets/nutcracker-banner.svg";
import NutcrackerLogoSrc from "../../img/assets/nutcracker-logo.svg";
import NutCrackerImageMobileSrc from "../../img/assets/nutcracker-intro-mobile.svg";

const Banner = ({ isFaq }) => {
  return (
    <BannerWrapper>
      <Content>
        <Column>
          <NutCrackerImageMobile
            src={NutCrackerImageMobileSrc}
            alt="nutcracker"
          />
          <MainLogo src={NutcrackerLogoSrc} />
          {isFaq ? (
            <>
              <SubtitleFAQ>
                Welcome to the only advent chainlendar ever created! <br /> Are
                you ready to crack all the blocks? <br />
                The nutcracker awaits you.
              </SubtitleFAQ>
              <CtaFaq as={Link} to={URL_BASE_NUTCRACKER_2022} />
            </>
          ) : (
            <>
              <Subtitle>The first advent chainlendar</Subtitle>
              <Cta as={Link} to={URL_FAQ} />
            </>
          )}
        </Column>
        <NutCrackerImage src={NutCrackerImageSrc} alt="nutcracker" />
      </Content>
    </BannerWrapper>
  );
};

export default Banner;
