import styled from "styled-components";

export const NftCardContainer = styled.img`
  width: 100%;
  max-width: 308px;
  height: auto;
  text-decoration: none;
  background-color: white;
  border-radius: 15px;
  filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.25));
`;

export const IdContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-4px);
  height: 56px;
  border-radius: 0 0 15px 15px;
  font-family: "Volkhov";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: black;
`;
