import React, { useState } from "react";
import VideoNft from "../../atoms/video-nft";
import MoonLoader from "react-spinners/MoonLoader";
import { Spinner, VideoLoaderWrapper } from "./styles";

const VideoLoader = ({ url, width, height, spinnerColor }) => {
  const [isVideoLoaded, setVideoLoaded] = useState(false);

  return (
    <VideoLoaderWrapper width={width} height={height}>
      <VideoNft url={url} setVideoLoaded={setVideoLoaded} />
      {isVideoLoaded ? null : (
        <Spinner width={width} height={height}>
          <MoonLoader
            color={spinnerColor}
            loading
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Spinner>
      )}
    </VideoLoaderWrapper>
  );
};

export default VideoLoader;
