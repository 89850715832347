import React from "react";
import { Link } from "react-router-dom";
import { TwitterShareButton } from "react-share";

import { TwitterBtn } from "../../atoms/twitter-btn/styles";
import {
  InfoBox,
  BoxTitle,
  Text,
  Btns,
  BatchLogo,
  BatchContainer,
  Label,
  HashField,
  HashContainer,
  SocialIcon,
  GoBackBtn,
  OwnerLabel,
  MetaDataContainer,
  MetaDataItem,
  Subtitle,
  IsYourNft,
} from "./styles";

import twitterIcon from "../../img/social/twitter.svg";
import batchLogoSrc from "../../img/logos/batch-logo.png";

const NftInfoBox = ({
  selectedNft,
  categoryBannerBall,
  categoryName,
  checkWhoOwnNft,
}) => {
  const textArray = [
    "Our unique NFT minted on Casper Blockchain for the participants of Nutcracker 2022 Advent Calendar.",
    "Special Nutcrackers for the most active community members minted on Casper Blockchain.",
    "Series of rare Nutcrackers offered for the ones who participated in our daily contests minted on Casper Blockchain.",
    "NFTs minted for our sponsors to be distributed among the community members.",
  ];

  //Remove "account-hash" string
  const removeAccountHash = (accountHash) => {
    return accountHash.substring(13);
  };

  return (
    <InfoBox>
      <BoxTitle>{categoryName}</BoxTitle>
      <Subtitle>{textArray[selectedNft.meta.category]}</Subtitle>
      <BatchContainer>
        <BatchLogo src={batchLogoSrc} />
        <Label>Batch</Label>
        <Text>{selectedNft.meta.batch}</Text>
      </BatchContainer>

      <MetaDataContainer>
        <MetaDataItem>
          <Label>Identification</Label>
          <Text>#{selectedNft.id}</Text>
        </MetaDataItem>
        <MetaDataItem>
          <Label>Collection</Label>
          <Text>{categoryName}</Text>
        </MetaDataItem>
        <MetaDataItem>
          <Label>Rarity</Label>
          <Text>
            {selectedNft.meta.rarity ? selectedNft.meta.rarity : "Regular"}
          </Text>
        </MetaDataItem>
        <MetaDataItem>
          <Label>Sponsor</Label>
          <Text>
            {selectedNft.meta.sponsor ? selectedNft.meta.sponsor : "Blockbites"}
          </Text>
        </MetaDataItem>
      </MetaDataContainer>

      <HashContainer>
        <OwnerLabel>Owner’s account hash</OwnerLabel>
        <HashField>{removeAccountHash(selectedNft.owner)}</HashField>
      </HashContainer>
      <IsYourNft>{checkWhoOwnNft() ? "This is your NFT" : ""}</IsYourNft>
      <Btns>
        <GoBackBtn as={Link} to={`/cat-${categoryBannerBall}`}>
          Go back
        </GoBackBtn>
        <TwitterShareButton
          title={"Check out this Chainlendar NFT!"}
          url={`http://chanlendar.io/nft-details`}
          hashtags={[
            "BlockBites",
            "NFT",
            "CasperAssociation",
            "CasperNetwork",
            "CasperBlockchain",
          ]}
          style={{ width: "100%" }}
        >
          <TwitterBtn as="div">
            <SocialIcon src={twitterIcon} />
            Share on Twitter
          </TwitterBtn>
        </TwitterShareButton>
      </Btns>
    </InfoBox>
  );
};

export default NftInfoBox;
