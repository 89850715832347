import styled from "styled-components";
import divideEllipse from "../../img/assets/divide_ellipse.png";

export const DotsDivider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35px;
  background: url(${divideEllipse}) space;
  margin: 6px 0;
`;
